import React from "react";
import { Col, Image, Row, Select } from "antd";
import { CoreLogo } from "@packages";
import './CoreLoginWrapper.scss'
import { useTranslation } from "react-i18next";
import LanguageMenu from "@components/main-layout/layout-wrapper/LanguageMenu";
import { CDN_ASSETS_URL } from "@utils/cdnAssetsUrl";

interface ILoginWrapperProps {
    children: React.ReactNode,
    coreImageSrc?: string,
}

const CoreLoginWrapper = (props: ILoginWrapperProps) => {

    const { t } = useTranslation();
    const { children, coreImageSrc } = props;

    const currentDate = new Date();

    return (
        <div className="core-login-layout-wrapper">
            <Row>
                <Col className="core-layout-content" sm={24} lg={24} xl={12}>
                    <div className='core-login-layout-logo'>
                        <CoreLogo size={'medium'} />
                        <LanguageMenu />
                    </div>
                    <div className='core-login-layout-content'>
                        {children}
                    </div>
                    <div className='core-login-layout-footer'>
                        <p>©{t('E_INVOICE_SOLUTIONS')}{currentDate.getFullYear()}</p>
                    </div>
                </Col>
                <Col className="core-layout-images" span={12}>
                    {coreImageSrc ? (
                        <>
                            <div className='core-login-wrapper'>
                                <Image width="807px" height="714px" src={coreImageSrc} preview={false} />
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="dot-image top">
                                <Image src={CDN_ASSETS_URL.dotTopImg} preview={false} />
                            </div>
                            <div className='core-login-wrapper'>
                                <Image src={CDN_ASSETS_URL.contactUsIcon} preview={false} />
                            </div>
                            <div className="dot-image bottom">
                                <Image src={CDN_ASSETS_URL.dotBottomImg} preview={false} />
                            </div>
                        </>
                    )}
                </Col>
            </Row>
        </div>
    );
};

export default CoreLoginWrapper;
