import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import LazySlider from '@components/custom-loader/LazySlider';
import TwoFactorAuthenticationStepper from '../common-features/setting/pages/two-factor authentication-stepper/TwoFactorAuthenticationStepper';

// import LayoutWrapper from '@components/main-layout/layout-wrapper/LayoutWrapper';
const LayoutWrapper = React.lazy(
    () => import('@components/main-layout/layout-wrapper/LayoutWrapper'),
);

const ArchivesList = React.lazy(
    () => import('@admin-features/archives/archives-list/ArchivesList'),
);

const TierList = React.lazy(
    () => import('@admin-features/subscription/page/tier/tier-list/TierList'),
);
const CouponList = React.lazy(
    () => import('@admin-features/subscription/page/coupon/coupon-list/CouponList'),
);

const TemplateHeader = React.lazy(
    () => import('@admin-features/template-generator/template-header/TemplateHeader'),
);

const TemplateTotal = React.lazy(
    () => import('@admin-features/template-generator/template-total/TemplateTotal'),
);

const TemplateFooter = React.lazy(
    () => import('@admin-features/template-generator/template-footer/TemplateFooter'),
);

const TemplateProperties = React.lazy(
    () => import('@admin-features/template-generator/template-properties/TemplateProperties'),
);

const TemplateItemTable = React.lazy(
    () => import('@admin-features/template-generator/template-item-table/TemplateItemTable'),
);

const TemplateList = React.lazy(
    () => import('@admin-features/templates/template-list/TemplateList'),
);

const BillingHome = React.lazy(
    () => import('@admin-features/billing/pages/billing-home/BillingHome'),
);

const Profile = React.lazy(() => import('../client-features/profile/pages/Profile'));

const PdfTemplateCreate = React.lazy(
    () => import('@admin-features/template/pdf/pages/template-create/PdfTemplateCreate'),
);

const PdfTemplateUpdate = React.lazy(
    () => import('@admin-features/template/pdf/pages/template-update/PdfTemplateUpdate'),
);

const PdfTemplateDetails = React.lazy(
    () => import('@admin-features/template/pdf/pages/template-details/PdfTemplateDetails'),
);

const UserList = React.lazy(
    () => import('../admin-features/user-management/user/pages/user-list/UserList'),
);

const UserDetails = React.lazy(
    () => import('../admin-features/user-management/user/pages/user-details/UserDetails'),
);

const UserUpdate = React.lazy(
    () => import('@admin-features/user-management/user/pages/user-update/UserUpdate'),
);

const UserInitiate = React.lazy(
    () => import('../admin-features/user-management/user/pages/user-initiate/UserInitiate'),
);

const ResourceGroupList = React.lazy(
    () => import('@admin-features/resource-group/pages/list/resource-group-list'),
);

const EmailTemplateList = React.lazy(
    () => import('@admin-features/template/email/pages/template-list/EmailTemplateList'),
);

const PdfTemplateList = React.lazy(
    () => import('@admin-features/template/pdf/pages/template-list/PdfTemplateList'),
);

const ResourceGroupCreate = React.lazy(
    () => import('@admin-features/resource-group/pages/create/resource-group-create'),
);

const EmailTemplateCreate = React.lazy(
    () => import('@admin-features/template/email/pages/template-create/EmailTemplateCreate'),
);

const EmailTemplateUpdate = React.lazy(
    () => import('@admin-features/template/email/pages/template-update/EmailTemplateUpdate'),
);

const SubscriptionDetails = React.lazy(
    () => import('@admin-features/subscription/page/subscription-details/SubscriptionDetails'),
);

const InvoiceDetails = React.lazy(
    () => import('@admin-features/subscription/page/invoice-details/InvoiceDetails'),
);

const SubscriptionConfiguration = React.lazy(
    () =>
        import(
            '@admin-features/subscription/page/subscription-configuration/SubscriptionConfiguration'
        ),
);

const EmailTemplateDetails = React.lazy(
    () => import('@admin-features/template/email/pages/template-details/EmailTemplateDetails'),
);

const Subscription = React.lazy(
    () => import('@admin-features/subscription/page/subscription-view/Subscription'),
);
const SubscriptionConfigurationUpdate = React.lazy(
    () =>
        import(
            '@admin-features/subscription/page/subscription-configuration-update/SubscriptionConfigurationUpdate'
        ),
);

const SettingTabs = React.lazy(
    () => import('../common-features/setting/pages/setting-tabs/SettingTabs'),
);

const WhatsNew = React.lazy(() => import('../common-features/whats-new/pages/whats-new/WhatsNew'));

const AddEditUpdate = React.lazy(
    () => import('../common-features/whats-new/pages/add-edit-update/AddEditUpdate'),
);

const RoleList = React.lazy(() => import('@admin-features/roles/pages/role-list/RoleList'));
const RoleCreate = React.lazy(() => import('@admin-features/roles/pages/role-create/RoleCreate'));
const RoleDetails = React.lazy(
    () => import('@admin-features/roles/pages/role-details/RoleDetails'),
);
const RoleUpdate = React.lazy(() => import('@admin-features/roles/pages/role-update/RoleUpdate'));

export default function AdminRouter() {
    return (
        <Suspense fallback={<LazySlider />}>
            <Routes>
                <Route path='' element={<LayoutWrapper />}>
                    <Route
                        path='profile'
                        element={
                            <Suspense fallback={<LazySlider />}>
                                <Profile />
                            </Suspense>
                        }
                    ></Route>
                    <Route
                        path='iam/users'
                        element={
                            <Suspense fallback={<LazySlider />}>
                                <UserList />
                            </Suspense>
                        }
                    ></Route>
                    <Route
                        path='iam/user/create'
                        element={
                            <Suspense fallback={<LazySlider />}>
                                <UserInitiate />
                            </Suspense>
                        }
                    ></Route>
                    <Route
                        path='iam/user/:email'
                        element={
                            <Suspense fallback={<LazySlider />}>
                                <UserDetails />
                            </Suspense>
                        }
                    ></Route>
                    <Route
                        path='iam/user/edit/:email'
                        element={
                            <Suspense fallback={<LazySlider />}>
                                <UserUpdate />
                            </Suspense>
                        }
                    ></Route>
                    <Route
                        path='archives'
                        element={
                            <Suspense fallback={<LazySlider />}>
                                <ArchivesList />
                            </Suspense>
                        }
                    ></Route>
                    <Route
                        path='/pdf-templates'
                        element={
                            <Suspense fallback={<LazySlider />}>
                                <TemplateList />
                            </Suspense>
                        }
                    ></Route>
                    <Route
                        path='/template-header'
                        element={
                            <Suspense fallback={<LazySlider />}>
                                <TemplateHeader />
                            </Suspense>
                        }
                    ></Route>
                    <Route
                        path='/template-total'
                        element={
                            <Suspense fallback={<LazySlider />}>
                                <TemplateTotal />
                            </Suspense>
                        }
                    ></Route>
                    <Route
                        path='/template-properties'
                        element={
                            <Suspense fallback={<LazySlider />}>
                                <TemplateProperties />
                            </Suspense>
                        }
                    ></Route>
                    <Route
                        path='/template-footer'
                        element={
                            <Suspense fallback={<LazySlider />}>
                                <TemplateFooter />
                            </Suspense>
                        }
                    ></Route>
                    <Route
                        path='/template-item-table'
                        element={
                            <Suspense fallback={<LazySlider />}>
                                <TemplateItemTable />
                            </Suspense>
                        }
                    ></Route>
                    <Route
                        path='/billing'
                        element={
                            <Suspense fallback={<LazySlider />}>
                                <BillingHome />
                            </Suspense>
                        }
                    ></Route>
                    <Route
                        path='/iam/resource-group'
                        element={
                            <Suspense fallback={<LazySlider />}>
                                <ResourceGroupList />
                            </Suspense>
                        }
                    ></Route>
                    <Route
                        path='/iam/resource-group/create'
                        element={
                            <Suspense fallback={<LazySlider />}>
                                <ResourceGroupCreate />
                            </Suspense>
                        }
                    ></Route>
                    <Route
                        path='/iam/role'
                        element={
                            <Suspense fallback={<LazySlider />}>
                                <RoleList />
                            </Suspense>
                        }
                    ></Route>
                    <Route
                        path='/iam/role/create'
                        element={
                            <Suspense fallback={<LazySlider />}>
                                <RoleCreate />
                            </Suspense>
                        }
                    ></Route>
                    <Route
                        path='/iam/role/:organizationId/:roleName'
                        element={
                            <Suspense fallback={<LazySlider />}>
                                <RoleDetails />
                            </Suspense>
                        }
                    ></Route>
                    <Route
                        path='/iam/role/update/:organizationId/:roleName'
                        element={
                            <Suspense fallback={<LazySlider />}>
                                <RoleUpdate />
                            </Suspense>
                        }
                    ></Route>

                    <Route
                        path='/subscription'
                        element={
                            <Suspense fallback={<LazySlider />}>
                                <Subscription />
                            </Suspense>
                        }
                    ></Route>
                    <Route
                        path='/subscription/details/:subscriptionId'
                        element={
                            <Suspense fallback={<LazySlider />}>
                                <SubscriptionDetails />
                            </Suspense>
                        }
                    ></Route>
                    <Route
                        path='/subscription/:subscriptionId/invoice/:invoiceId'
                        element={
                            <Suspense fallback={<LazySlider />}>
                                <InvoiceDetails />
                            </Suspense>
                        }
                    ></Route>
                    <Route
                        path='/subscription/tiers'
                        element={
                            <Suspense fallback={<LazySlider />}>
                                <TierList />
                            </Suspense>
                        }
                    ></Route>
                    <Route
                        path='/subscription/coupons'
                        element={
                            <Suspense fallback={<LazySlider />}>
                                <CouponList />
                            </Suspense>
                        }
                    ></Route>
                    <Route
                        path='/subscription/configuration'
                        element={
                            <Suspense fallback={<LazySlider />}>
                                <SubscriptionConfiguration />
                            </Suspense>
                        }
                    ></Route>
                    <Route
                        path='/subscription/configuration/update'
                        element={
                            <Suspense fallback={<LazySlider />}>
                                <SubscriptionConfigurationUpdate />
                            </Suspense>
                        }
                    ></Route>

                    <Route
                        path='/template/email'
                        element={
                            <Suspense fallback={<LazySlider />}>
                                <EmailTemplateList />
                            </Suspense>
                        }
                    ></Route>
                    <Route
                        path='/template/email/create'
                        element={
                            <Suspense fallback={<LazySlider />}>
                                <EmailTemplateCreate />
                            </Suspense>
                        }
                    ></Route>
                    <Route
                        path='/template/email/update/:TemplateId'
                        element={
                            <Suspense fallback={<LazySlider />}>
                                <EmailTemplateUpdate />
                            </Suspense>
                        }
                    ></Route>
                    <Route
                        path='/template/email/details/:TemplateId'
                        element={
                            <Suspense fallback={<LazySlider />}>
                                <EmailTemplateDetails />
                            </Suspense>
                        }
                    ></Route>
                    <Route
                        path='/template/pdf'
                        element={
                            <Suspense fallback={<LazySlider />}>
                                <PdfTemplateList />
                            </Suspense>
                        }
                    ></Route>
                    <Route
                        path='/template/pdf/create'
                        element={
                            <Suspense fallback={<LazySlider />}>
                                <PdfTemplateCreate />
                            </Suspense>
                        }
                    ></Route>
                    <Route
                        path='/template/pdf/details/:TemplateId'
                        element={
                            <Suspense fallback={<LazySlider />}>
                                <PdfTemplateDetails />
                            </Suspense>
                        }
                    ></Route>
                    <Route
                        path='/template/pdf/update/:TemplateId'
                        element={
                            <Suspense fallback={<LazySlider />}>
                                <PdfTemplateUpdate />
                            </Suspense>
                        }
                    ></Route>
                    <Route
                        path='setting/two-factor-authentication'
                        element={
                            <Suspense fallback={<LazySlider />}>
                                <TwoFactorAuthenticationStepper />
                            </Suspense>
                        }
                    ></Route>
                    <Route
                        path='setting'
                        element={
                            <Suspense fallback={<LazySlider />}>
                                <SettingTabs />
                            </Suspense>
                        }
                    ></Route>
                    <Route
                        path='/whats-new'
                        element={
                            <Suspense fallback={<LazySlider />}>
                                <WhatsNew />
                            </Suspense>
                        }
                    ></Route>
                    <Route
                        path='/whats-new/edit/'
                        element={
                            <Suspense fallback={<LazySlider />}>
                                <AddEditUpdate />
                            </Suspense>
                        }
                    ></Route>
                    <Route
                        path='/whats-new/add/'
                        element={
                            <Suspense fallback={<LazySlider />}>
                                <AddEditUpdate />
                            </Suspense>
                        }
                    ></Route>
                </Route>
            </Routes>
        </Suspense>
    );
}
