import React, { useEffect, useState } from 'react';
import { Upload, message } from 'antd';
import type { UploadFile } from 'antd';
import { RcFile } from 'antd/es/upload';
import { useTranslation } from 'react-i18next';
import CropImageModal from './CropImageModal';
import './CoreFileLogo.scss';
import GenericTooltip from '@components/common/GenericTooltip';

export interface ILogoInfo {
  width?: number;
  height?: number;
  url: string;
}

export interface ICoreFileCropperProps {
  url?: string;
  onFileUpload?: (info: ILogoInfo) => void,
  uploadHint?: string,
  maxWidth?: number,
  maxHeight?: number,
  maxSize?: number, // maxSize kb
  setLogoObject: (info: ILogoInfo) => void,
}

export default function CoreFileLogo({ maxSize= 800, url, maxWidth = 500, maxHeight = 500, onFileUpload, setLogoObject }: ICoreFileCropperProps) {
  const { t } = useTranslation();
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [logoUrl, setLogoUrl] = useState<string | undefined>(url);
  const [modalVisible, setModalVisible] = useState(false);

  const handleBeforeUpload = async (file: RcFile) => {
    const isImage = file.type.startsWith('image/');
    if (!isImage) {
      message.error(t('YOU_CAN_ONLY_UPLOAD_IMAGE_FILE'));
      return Upload.LIST_IGNORE;
    }

    setImageFile(file);
    setModalVisible(true); // Open the modal for cropping
    return Upload.LIST_IGNORE;
  };

  const handleCropConfirm = (logoInfo: ILogoInfo) => {
    setLogoUrl(logoInfo.url);
    onFileUpload && onFileUpload(logoInfo);
    setModalVisible(false);
    setLogoObject(logoInfo);
  };

  const handleRemove = () => {
    onFileUpload && onFileUpload({} as ILogoInfo);
    setLogoUrl(undefined);
    setModalVisible(false);
  };

  useEffect(() =>{
    setLogoUrl(url);
  },[url])

  return (
    <div>
      <Upload

        listType="picture-card"
        fileList={logoUrl ? [{url: logoUrl, name: 'test', uid: '-1',}]: []}
        beforeUpload={handleBeforeUpload}
        onRemove={handleRemove}
        onPreview={() => {
          if (logoUrl) {
            const win = window.open();
            win?.document.write(`<img src="${logoUrl}" style="max-width: 100%"/>`);
          }
        }}
      >
         {!logoUrl && <p>
                + Upload
                <GenericTooltip text={
                  `File Format: PNG, JPG
                  ${maxWidth && maxHeight && `(${maxWidth}x${maxHeight})` || ''}.
                  ${maxSize ? `Maximum file size: ${maxSize}KB`: ''}`} />
                </p>} 
      </Upload>

      <CropImageModal
        visible={modalVisible}
        imageFile={imageFile}
        onCancel={() => setModalVisible(false)}
        onConfirm={handleCropConfirm}
        maxHeight={maxHeight}
        maxSize={maxSize}
        maxWidth={maxWidth}
      />
    </div>
  );
}
