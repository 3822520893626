import { RootState } from '@store/store';
import { cloneDeep } from 'lodash';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

const useGetHeaderTitle = (): string => {
    const location = useLocation();
    const inventoryTitle = useSelector((state: RootState) => state.dynamicTitle.title);
    const customerTitle = useSelector((state: RootState) => state.dynamicTitle.customerTitle);
    const [title, setTitle] = useState<string>('');
    const getTitle = (pathName: string): void => {
        const detailReplaceRegex = pathName.replace(/(details\/).*/, '$1');
        const createReplaceRegex = detailReplaceRegex.replace(/(create\/).*/, '$1');
        const updateReplaceRegex = createReplaceRegex.replace(/(update\/).*/, '$1');

        switch (updateReplaceRegex) {
            case '/dashboard':
                return setTitle('DASHBOARD');
            case '/organizations':
                return setTitle('ORGANIZATIONS');
            case '/organizations/update/':
                return setTitle('ORGANIZATION_UPDATE');
            case '/organizations/entity/create/':
                return setTitle('ADD_ENTITY');
            case '/organizations/entity/update/':
                return setTitle('ENTITY_UPDATE');
            case '/organizations/device/create/':
                return setTitle('ADD_DEVICE');
            case '/organizations/device/update/':
                return setTitle('UPDATE_DEVICE');
            case '/profile':
                return setTitle('MY_PROFILE');
            case '/setting':
                return setTitle('SETTINGS');
            case '/archives':
                return setTitle('ARCHIVES');
            case '/customers':
                return setTitle('CUSTOMERS');
            case '/customers/details/':
                return setTitle('CUSTOMERS');
            case '/customers/create':
                return setTitle('NEW_CUSTOMER');
            case '/customers/update/':
                return setTitle('Update Customer');
            case '/customer-stepper':
                return setTitle('Customer Stepper');
            case '/items':
                return setTitle('PRODUCTS');
            case '/items/create':
                return setTitle('New Item');
            case '/items/update/':
                return setTitle('Update Item');
            case '/preference':
                return setTitle('Preference');
            case '/inventory-stepper':
                return setTitle(`${inventoryTitle}`);
            case '/e-invoices':
                return setTitle(`E_INVOICES`);
            case '/draft-invoices':
                return setTitle('Draft Invoices');
            case '/e-invoices/details/':
                return setTitle('E_INVOICES');
            case '/e-invoices/create':
                return setTitle('NEW_EINVOICE');
            case '/create-credit-note':
                return setTitle('NEW_CREDIT_NOTE');
            case '/create-debit-note':
                return setTitle('NEW_DEBIT_NOTE');
            case '/create-simplified-credit-note':
                return setTitle('NEW_SIMPLIFIED_CREDIT_NOTE');
            case '/create-simplified-debit-note':
                return setTitle('NEW_SIMPLIFIED_DEBIT_NOTE');
            case '/users':
                return setTitle('Users');
            case '/roles':
                return setTitle('Roles');
            case '/policies':
                return setTitle('Policies');
            case '/actions':
                return setTitle('Actions');
            case '/templates':
                return setTitle('INVOICE_TEMPLATES');
            case '/template-header':
                return setTitle('Header');
            case '/template-footer':
                return setTitle('Footer');
            case '/template-properties':
                return setTitle('TEMPLATE_PROPERTIES');
            case '/template-item-custom-pagination':
                return setTitle('Item Table');
            case '/template-total':
                return setTitle('Total');
            case '/support':
                return setTitle('Support');
            case '/billing':
                return setTitle('Billing');
            case '/template':
                return setTitle('Templates');
            case '/template-details/:id':
                return setTitle('Template Details');
            case '/template-create':
                return setTitle('Template Create');
            case '/template/update/:id':
                return setTitle('Template Update');
            case '/subscribe':
                return setTitle('SUBSCRIPTION');
            case '/api-services':
                return setTitle('API & Services');
            case '/api-services/create':
                return setTitle('API & Service Create');
            case '/api-services/update/':
                return setTitle('API & Service Update');
            case '/api-services/details/':
                return setTitle('API & Service Details');
            case '/admin/iam/resource-group':
                return setTitle('Resource Groups');
            case '/admin/iam/resource-group/create':
                return setTitle('Resource Group Create');
            case '/admin/iam/users':
                return setTitle('User Managements');
            case '/admin/iam/user/create':
                return setTitle('User Create');
            case '/admin/subscription':
                return setTitle('Subscriptions');
            case '/admin/subscription/details/':
                return setTitle('Subscription Details');
            case '/admin/subscription/tiers':
                return setTitle('Tiers');
            case '/admin/subscription/coupons':
                return setTitle('Coupons');
            case '/admin/subscription/configuration':
                return setTitle('Configuration');
            case '/admin/subscription/configuration/update':
                return setTitle('Configuration Update');
            case '/admin/template/email':
                return setTitle('Email Templates');
            case '/admin/template/email/details/':
                return setTitle('Email Template Details');
            case '/admin/template/email/create':
                return setTitle('Email Template Create');
            case '/admin/template/email/update/':
                return setTitle('Email Template Update');
            case '/admin/template/pdf':
                return setTitle('Pdf Templates');
            case '/admin/template/pdf/details/':
                return setTitle('Pdf Template Details');
            case '/admin/template/pdf/create':
                return setTitle('Pdf Template Create');
            case '/admin/template/pdf/update/':
                return setTitle('Pdf Template Update');
            case '/organizations/:id/device/create':
                return setTitle('New Device');
            case '/memberships':
                return setTitle('Memberships');
            case '/memberships/invitations':
                return setTitle('Invitations');
            case '/memberships/invitations/create':
                return setTitle('Invite User');
            default:
                return setTitle('');
        }
    };
    useEffect(() => {
        const pathName = cloneDeep(location.pathname);
        getTitle(pathName);
    }, [location.pathname, inventoryTitle, customerTitle]);
    return title;
};

export default useGetHeaderTitle;
