import { QuestionCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { FC } from 'react';

interface GenericTooltipProps {
    text: any;
    overlayClassName?: string;
}

const GenericTooltip: FC<GenericTooltipProps> = ({ text, overlayClassName }) => {
    return (
        <Tooltip
            title={text}
            className='generic-tooltip'
            overlayClassName={overlayClassName}
            overlayStyle={{ backgroundColor: 'rgba(102, 112, 133, 0.85)', borderRadius: '4px' }}
        >
            <QuestionCircleOutlined
                style={{ fontSize: '12px', position: 'absolute', marginTop: '3px' }}
            />
        </Tooltip>
    );
};
export default GenericTooltip;
