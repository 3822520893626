import { localStoreAPI } from '@service/local-storage.service';
import { useEffect, useState } from 'react';

export default function useGetAnonymousToken() {
    const [isLoading, setIsLoading] = useState(false);
    const [anonymousToken, setAnonymousToken] = useState<string>();

    const getAnonymousToken = () => {
        setIsLoading(true);
        const anonymousToken = localStoreAPI.getAnonymousToken();
        anonymousToken && setAnonymousToken(anonymousToken);

        setTimeout(() => {
            getAnonymousToken();
        }, 1000);
        setIsLoading(false);
    };

    useEffect(() => {
        getAnonymousToken();
    }, []);

    return { isLoading, anonymousToken };
}
