import { Badge, Drawer, Dropdown, Image, Space } from 'antd';
import React, { useState } from 'react';
import {
    BellOutlined,
    LogoutOutlined,
    MenuFoldOutlined,
    SettingOutlined,
    UserOutlined,
} from '@ant-design/icons';
import './NavigationDrawer.scss';
import SubscriptionUpgradePanel from '@components/subscription-upgrade-panel/SubscriptionUpgradePanel';
import LanguageMenu from '@components/main-layout/layout-wrapper/LanguageMenu';
import { NotificationDropDown } from '@components';
import ProfileDropDown from '@components/main-layout/profile-drop-down/ProfileDropDown';
import { DownArrowIcon } from '@components/icons';
import { useSelector } from 'react-redux';
import { RootState } from '@store/store';
import { NavLink } from 'react-router-dom';
import { onClearUserInfo } from '@store/slices/userSlice';
import { logOutUser } from '@store/slices/authSlice';
import { useAppDispatch } from '@store/hooks';
import { CDN_ASSETS_URL } from '@utils/cdnAssetsUrl';

const NavigationDrawer = () => {
    const dispatch = useAppDispatch();
    const { FirstName, LastName, DisplayPictureUrl } = useSelector(
        (state: RootState) => state.user.userDetails,
    );

    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [notificationDropdownVisible, setNotificationDropdownVisible] = useState(false);

    const handleVisibleChange = () => {
        setDropdownVisible(!dropdownVisible);
    };

    const handleNotification = () => {
        setNotificationDropdownVisible(!notificationDropdownVisible);
    };

    const [open, setOpen] = useState(false);

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const logOutPortal = async () => {
        await dispatch(onClearUserInfo());
        await dispatch(logOutUser());
    };

    return (
        <div className='drawer-wrapper'>
            <div className='drawer-inner-wrapper'>
                <Dropdown
                    open={dropdownVisible}
                    trigger={['click']}
                    dropdownRender={(menu) => (
                        <ProfileDropDown handleVisibleChange={handleVisibleChange} />
                    )}
                    onOpenChange={handleVisibleChange}
                >
                    <Space className='drop-down-profile-button'>
                        <Image
                            className='profile-image'
                            width={30}
                            height={30}
                            src={CDN_ASSETS_URL.avatarImg}
                            preview={false}
                        />

                        <div className='org-name'>
                            {FirstName} {LastName}
                            <DownArrowIcon
                                className={
                                    dropdownVisible ? 'drop-down-icon-clicked' : 'drop-down-icon'
                                }
                            />
                        </div>
                    </Space>
                </Dropdown>
                <MenuFoldOutlined onClick={showDrawer} className='drawer-button' />
            </div>
            <Drawer placement='right' width={300} onClose={onClose} open={open}>
                <div className='drawer-action-group'>
                    <SubscriptionUpgradePanel />
                    <LanguageMenu />

                    <div className='drawer-action-button'>
                        <UserOutlined />
                        <NavLink to={'/subscribe'} className='drawer-action-button-link'>
                            Subscribe
                        </NavLink>
                    </div>

                    <div className='drawer-notification'>
                        <Dropdown
                            open={notificationDropdownVisible}
                            trigger={['click']}
                            dropdownRender={(menu) => <NotificationDropDown />}
                            onOpenChange={handleNotification}
                        >
                            <Badge count={0} className='badge'>
                                <BellOutlined className='notification-bar' />
                                <p>Notifications</p>
                            </Badge>
                        </Dropdown>
                    </div>

                    <div className='drawer-action-button'>
                        <UserOutlined />
                        <NavLink to={'/profile'} className='drawer-action-button-link'>
                            My account
                        </NavLink>
                    </div>
                    <div className='drawer-action-button'>
                        <SettingOutlined />
                        <NavLink to={'/setting'} className='drawer-action-button-link'>
                            Settings
                        </NavLink>
                    </div>
                    <div className='drawer-action-button'>
                        <LogoutOutlined />
                        <NavLink
                            to={'/login'}
                            onClick={logOutPortal}
                            className='drawer-action-button-link'
                        >
                            Sign Out
                        </NavLink>
                    </div>
                </div>
            </Drawer>
        </div>
    );
};

export default NavigationDrawer;
