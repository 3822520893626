import { AXIOS_INSTANCE } from '@config/axios-config';
import { APP_CONFIG } from '@config/constants';
import { localStoreAPI } from '@service/local-storage.service';
import { AxiosError } from 'axios';

export const AdminPersonaAPI = {
    getPersonas: async (): Promise<any> => {
        const url = APP_CONFIG.identityUrl + `PersonaQuery/Personas`;
        try {
            const response = await AXIOS_INSTANCE.get<any>(url);
            return response?.data?.Personas;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },

    updatePersona: async (persona: any): Promise<any> => {
        const url = APP_CONFIG.identityUrl + `PersonaCommand/Update`;
        try {
            const response = await AXIOS_INSTANCE.post<any>(url, persona);
            return response.data;
        } catch (error) {
            const err = error as AxiosError;
            throw err?.response?.data;
        }
    },

    switchPersona: async (subscriptionId: string): Promise<any> => {
        const url = APP_CONFIG.identityUrl + `Token`;
        const refreshToken = localStoreAPI.getRefreshToken();
        try {
            const response = await AXIOS_INSTANCE.post<any>(url, {
                grant_type: 'persona',
                subscription_id: subscriptionId,
                refresh_token_id: refreshToken,
            });
            const data = response.data;
                if (Object.prototype.hasOwnProperty.call(data, 'refreshTokenId')) {
                    localStoreAPI.setAccessToken(data?.token);
                    localStoreAPI.setRefreshToken(data?.refreshTokenId);
                }
                return response.data;
        } catch (error) {
            const err = error as AxiosError;
                throw err;
        }
    },
};
