import React, { useEffect, useState } from 'react';
import {
    UserOutlined,
    LogoutOutlined,
    SettingOutlined,
    TeamOutlined,
    InfoCircleOutlined,
} from '@ant-design/icons';
import { Divider, Image, Modal } from 'antd';
import './ProfileDropDown.scss';
import { NavLink, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { logOutUser } from '@store/slices/authSlice';
import { onClearUserInfo, selectLoggedUserInfo, userSelectRole } from '@store/slices/userSlice';
import { getPersons } from '@store/slices/admin/personaSlice';
import { useSelector } from 'react-redux';
import { RootState } from '@store/store';
import { DEFAULT_EMPTY_VALUE } from '@config/constants';
import { useTranslation } from 'react-i18next';
import { CDN_ASSETS_URL } from '@utils/cdnAssetsUrl';
import { CoreButton } from '@packages';

interface ProfileDropDownProps {
    handleVisibleChange: () => void;
}

const ProfileDropDown: React.FC<ProfileDropDownProps> = ({ handleVisibleChange }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [isHintModalVisible, setIsHintModalVisible] = useState<boolean>(false);
    const [isScrollOrganization, setIsScrollOrganization] = useState<boolean>(false);
    const { data: personaData } = useAppSelector((state: RootState) => state.persona.personas);

    const logOutPortal = async () => {
        await dispatch(onClearUserInfo());
        await dispatch(logOutUser());
        navigate('/login');
    };

    const { isAdmin, isAppUser } = useAppSelector(userSelectRole);

    useEffect(() => {
        dispatch(getPersons());
    }, []);

    const { organizations } = useSelector((state: RootState) => state.organization);

    const { UserId, FirstName, LastName, Email, DisplayPictureUrl } =
        useAppSelector(selectLoggedUserInfo).userDetails;

    const navigate = useNavigate();
    const tooltip = (
        <>
            <p>
                <b>What is a Persona?</b>
            </p>
            <p>
                Think of a persona as a unique identity or "hat" that carries specific roles and
                permissions tied to a subscription. Just like you might wear different hats for
                different occasions, each persona lets you access the tools and features you need
                for a particular part of your life—whether that's for work, personal projects, or
                any other activity.
            </p>

            <p>
                <b>How Does It Work?</b>
            </p>

            <p>
                <b>Multiple Personas</b>: You can have more than one persona, each linked to a
                different subscription. This means you can easily switch between roles depending on
                the task at hand.
            </p>

            <p>
                <b>Creating a Persona</b>: When you accept an invitation to join a subscription, a
                new persona is automatically created for you. This new persona comes with its own
                set of permissions and features specific to that subscription.
            </p>

            <p>
                With our persona system, managing your various roles becomes seamless and secure.
                Enjoy the flexibility of tailoring your experience to match your needs—one persona
                at a time!
            </p>
        </>
    );

    return (
        <>
            <div className='drop-down-profile'>
                <div className='profile-details'>
                    <Image
                        className='profile-image'
                        width={60}
                        height={60}
                        src={DisplayPictureUrl || CDN_ASSETS_URL.avatarImg}
                        preview={false}
                    />
                    <p className='user-name'>
                        {FirstName} {LastName}
                    </p>
                    <p className='user-handles'>
                        {' '}
                        {t('USER_ID')}: {UserId.slice(-12)}
                    </p>
                    <p className='user-handles'>
                        {'Active Persona/Subscription ID'}:{' '}
                        {organizations[0]?.SubscriptionId.slice(-12) || DEFAULT_EMPTY_VALUE}
                    </p>

                    <p className='user-handles-email'>
                        {t('EMAIL')}: {Email}
                    </p>
                </div>
                <Divider className='custom-divider' />
                <>
                    <div className='my-persona'>
                        <div className='my-persona-heading'>
                            <p>
                                <span>{'Persona(s)'} </span>
                                <InfoCircleOutlined
                                    onClick={() => {
                                        setIsHintModalVisible(true);
                                    }}
                                />
                            </p>
                            <NavLink
                                to={'#'}
                                className='manage-button'
                                onClick={handleVisibleChange}
                            >
                                {t('MANAGE')}
                            </NavLink>
                        </div>
                    </div>
                    <div className='persona-list-wrapper'>
                        <div
                            className='persona-scroller'
                            style={{
                                overflowY: isScrollOrganization ? 'scroll' : 'hidden',
                                height: isScrollOrganization ? '160px' : '100px',
                            }}
                        >
                            {personaData?.map((persona, index) => (
                                <div className='persona-list' key={index}>
                                    <div className='org-icon'>
                                        <TeamOutlined />
                                    </div>
                                    <div className='persona-details'>
                                        <p className='user-name'>
                                            {persona.Name || DEFAULT_EMPTY_VALUE}
                                        </p>
                                        <p className='user-handles'>
                                            {'Subscription ID'}:{' '}
                                            {persona?.SubscriptionId.slice(-12)}
                                        </p>
                                    </div>
                                </div>
                            ))}
                        </div>
                        {personaData.length >= 3 && !isScrollOrganization && (
                            <div className='see-more' onClick={() => setIsScrollOrganization(true)}>
                                {t('SEE_MORE')}
                            </div>
                        )}
                    </div>
                    <Divider className='custom-divider' />
                </>

                <div className='action-buttons'>
                    {isAppUser && (
                        <div className='button-box'>
                            <UserOutlined />
                            <NavLink
                                to={'/subscribe'}
                                className='button-label'
                                onClick={handleVisibleChange}
                            >
                                {t('SUBSCRIPTION')}
                            </NavLink>
                        </div>
                    )}

                    <div className='button-box'>
                        <UserOutlined />
                        <NavLink
                            to={isAdmin ? '/admin/profile': '/profile'}
                            className='button-label'
                            onClick={handleVisibleChange}
                        >
                            {t('MY_ACCOUNT')}
                        </NavLink>
                    </div>
                    <div className='button-box'>
                        <SettingOutlined />
                        <NavLink
                            to={`${isAdmin ? '/admin/setting' : '/setting'}`}
                            className='button-label'
                            onClick={handleVisibleChange}
                        >
                            {t('SETTINGS')}
                        </NavLink>
                    </div>
                    <div className='button-box'>
                        <LogoutOutlined />
                        <NavLink to={'/login'} className='button-label' onClick={logOutPortal}>
                            {t('SIGN_OUT')}
                        </NavLink>
                    </div>
                </div>
            </div>
            <Modal
                zIndex={10000}
                open={isHintModalVisible}
                onCancel={() => setIsHintModalVisible(false)}
                closable={false}
                width={500}
                footer={[
                    <div key='footer'>
                        <CoreButton
                            key={1}
                            text='Ok'
                            type='primary'
                            onClick={() => setIsHintModalVisible(false)}
                        />
                    </div>,
                ]}
            >
                {tooltip}
            </Modal>
        </>
    );
};

export default ProfileDropDown;
