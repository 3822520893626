import { AxiosError } from 'axios';
import { AXIOS_INSTANCE } from '@config/axios-config';
import { APP_CONFIG } from '@config/constants';
import {
    ICreateAlertPayload,
    IDeleteAlertPayload,
    ISubscribe,
    ISubscribePlanDetails,
    ISubscriptionConfiguration,
    ISubscriptionInvoicesPayload,
    IUpdateAlertPayload,
    SubscriptionOverview,
} from '@models/subscription.model';
import {
    ICreateCouponPayload,
    ICreateTierPayload,
    ISingleTierPayload,
    IUpdateCouponPayload,
    IUpdateTierPayload,
} from '@models/billing.model';
import { processQuery } from '@utils/processQuery';

export const SubscribeAPI = {
    GetSubscriptionOverview: async (payload?: {
        SubscriptionId: string;
    }): Promise<SubscriptionOverview> => {
        const url =
            APP_CONFIG.eisUrl + `SubscriptionQuery/SubscriptionOverview${processQuery(payload)}`;
        try {
            const response = await AXIOS_INSTANCE.get<any>(url);
            return response?.data?.Result || {};
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },

    GetSubscription: async (payload?: {
        SubscriptionId: string;
    }): Promise<ISubscribePlanDetails> => {
        const url = APP_CONFIG.eisUrl + `SubscriptionQuery/Subscription${processQuery(payload)}`;
        try {
            const response = await AXIOS_INSTANCE.get<any>(url);
            return response?.data?.Result || {};
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },
    GetSubscriptionConfiguration: async (): Promise<ISubscriptionConfiguration> => {
        const url = APP_CONFIG.eisUrl + `SubscriptionQuery/GetSubscriptionConfiguration`;
        try {
            const response = await AXIOS_INSTANCE.get<any>(url);

            return response?.data || {};
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },

    UpdateSubscriptionConfiguration: async (payload: ISubscriptionConfiguration): Promise<any> => {
        const url = APP_CONFIG.eisUrl + `SubscriptionCommand/SubscriptionConfiguration`;
        try {
            const response = await AXIOS_INSTANCE.post<any>(url, payload);
            return response?.data;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },

    CreateTier: async (payload: ICreateTierPayload): Promise<any> => {
        const url = APP_CONFIG.eisUrl + `TierCommand/CreateTier`;
        try {
            const response = await AXIOS_INSTANCE.post<ICreateTierPayload>(url, payload);
            return response;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },

    GetTiers: async (): Promise<any> => {
        const url = APP_CONFIG.eisUrl + `TierCommand/GetTiers`;
        try {
            const response = await AXIOS_INSTANCE.post<any>(url, {});
            return response?.data?.Result;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },

    GetTier: async (payload: ISingleTierPayload): Promise<any> => {
        const url = APP_CONFIG.eisUrl + `TierCommand/GetTier`;
        try {
            const response = await AXIOS_INSTANCE.post<any>(url, payload);
            return response?.data?.Result;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },

    Subscribe: async (payload: ISubscribe): Promise<any> => {
        const url = APP_CONFIG.eisUrl + `SubscriptionCommand/Subscribe`;
        try {
            const response = await AXIOS_INSTANCE.post<any>(url, payload);
            return response?.data?.Result;
        } catch (error) {
            const err = error as AxiosError;
            throw err?.response?.data;
        }
    },

    UpdateTire: async (payload: IUpdateTierPayload): Promise<any> => {
        const url = APP_CONFIG.eisUrl + `TierCommand/UpdateTier`;
        try {
            const response = await AXIOS_INSTANCE.post<IUpdateTierPayload>(url, payload);
            return response;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },

    GetCredits: async (): Promise<any> => {
        const url = APP_CONFIG.eisUrl + `SubscriptionQuery/Credits`;
        try {
            const response = await AXIOS_INSTANCE.get<any>(url);
            return response?.data?.Result;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },

    GetSubscriptionInvoices: async (payload: ISubscriptionInvoicesPayload): Promise<any> => {
        const url = APP_CONFIG.eisUrl + `SubscriptionCommand/Invoices`;
        try {
            const response = await AXIOS_INSTANCE.post<any>(url, payload);
            return response?.data?.Invoices;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },

    GetSubscriptionInvoice: async (invoiceId: string): Promise<any> => {
        const url = APP_CONFIG.eisUrl + `SubscriptionQuery/Invoice${processQuery({ invoiceId })}`;
        try {
            const response = await AXIOS_INSTANCE.get<any>(url);
            return response?.data?.Result;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },

    GetApplyCoupon: async (code: string): Promise<any> => {
        const url = APP_CONFIG.eisUrl + `CouponQuery/Apply${processQuery({ Code: code })}`;
        try {
            const response = await AXIOS_INSTANCE.get<any>(url);
            return response?.data?.Result;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },

    GetSubscriptionUsage: async (days: number): Promise<any> => {
        const url = APP_CONFIG.eisUrl + `SubscriptionQuery/Usage${processQuery({ days })}`;
        try {
            const response = await AXIOS_INSTANCE.get<any>(url);
            return response?.data;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },

    CancelSubscription: async (payload: { Reason: string }): Promise<any> => {
        const url = APP_CONFIG.eisUrl + `SubscriptionCommand/Cancel`;
        try {
            const response = await AXIOS_INSTANCE.post<any>(url, payload);
            return response?.data;
        } catch (error) {
            const err = error as AxiosError;
            throw err?.response;
        }
    },

    UndoCancellation: async (): Promise<any> => {
        const url = APP_CONFIG.eisUrl + `SubscriptionCommand/UndoCancellation`;
        try {
            const response = await AXIOS_INSTANCE.post<any>(url);
            return response?.data;
        } catch (error) {
            const err = error as AxiosError;
            throw err?.response;
        }
    },

    GetAlerts: async (): Promise<any> => {
        const url = APP_CONFIG.eisUrl + `AlertQuery/Alerts`;
        try {
            const response = await AXIOS_INSTANCE.get<any>(url);
            return response?.data?.Result;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },

    CreateAlert: async (payload: ICreateAlertPayload): Promise<any> => {
        const url = APP_CONFIG.eisUrl + `AlertCommand/Create`;
        try {
            const response = await AXIOS_INSTANCE.post<any>(url, payload);
            return response?.data;
        } catch (error) {
            const err = error as AxiosError;
            throw err?.response;
        }
    },

    UpdateAlert: async (payload: IUpdateAlertPayload): Promise<any> => {
        const url = APP_CONFIG.eisUrl + `AlertCommand/Update`;
        try {
            const response = await AXIOS_INSTANCE.post<any>(url, payload);
            return response?.data;
        } catch (error) {
            const err = error as AxiosError;
            throw err?.response;
        }
    },

    DeleteAlert: async (payload: IDeleteAlertPayload): Promise<any> => {
        const url = APP_CONFIG.eisUrl + `AlertCommand/Delete`;
        try {
            const response = await AXIOS_INSTANCE.post<any>(url, payload);
            return response?.data;
        } catch (error) {
            const err = error as AxiosError;
            throw err?.response;
        }
    },

    GetCoupons: async (): Promise<any> => {
        const url = APP_CONFIG.eisUrl + `CouponCommand/Coupons`;
        try {
            const response = await AXIOS_INSTANCE.post<any>(url, {});
            return response?.data?.Coupons;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },

    CreateCoupon: async (payload: ICreateCouponPayload): Promise<any> => {
        const url = APP_CONFIG.eisUrl + `CouponCommand/Create`;
        try {
            const response = await AXIOS_INSTANCE.post<ICreateCouponPayload>(url, payload);
            return response;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },

    UpdateCoupon: async (payload: IUpdateCouponPayload): Promise<any> => {
        const url = APP_CONFIG.eisUrl + `CouponCommand/Update`;
        try {
            const response = await AXIOS_INSTANCE.post<IUpdateCouponPayload>(url, payload);
            return response;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },

    RemoveCoupon: async (payload: { Code: string }): Promise<any> => {
        const url = APP_CONFIG.eisUrl + `CouponCommand/Update`;
        try {
            const response = await AXIOS_INSTANCE.post<ICreateCouponPayload>(url, payload);
            return response;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },
};
