import { Modal } from 'antd';
import './ConfirmationModal.scss';
import { CoreButton, CoreTitle } from '@packages';
import { useTranslation } from 'react-i18next';
import { CDN_ASSETS_URL } from '@utils/cdnAssetsUrl';

interface IConfirmationModalProps {
    title: string;
    description?: string;
    buttonType?: string;
    isDeleteModalOpen: boolean;
    handleDeleteOk: () => void;
    handleDeleteCancel: () => void;
    isLoading?: boolean;
    buttonText?: string;
}

const ConfirmationModal = (props: IConfirmationModalProps) => {
    const { t } = useTranslation();
    const {
        title,
        description,
        buttonType,
        isDeleteModalOpen,
        handleDeleteOk,
        handleDeleteCancel,
        isLoading,
        buttonText,
    } = props;

    return (
        <>
            <Modal
                className='delete-modal-wrapper'
                width={400}
                open={isDeleteModalOpen}
                onOk={handleDeleteOk}
                onCancel={handleDeleteCancel}
                footer={[
                    <CoreButton
                        key={1}
                        text={t('CANCEL')}
                        type='basic'
                        onClick={handleDeleteCancel}
                    />,
                    <CoreButton
                        key={2}
                        text={buttonText ? buttonText : buttonType ? buttonType : 'Delete'}
                        type={buttonType ? 'primary' : 'danger'}
                        onClick={handleDeleteOk}
                        loading={isLoading ?? false}
                    />,
                ]}
            >
                <img
                    src={
                        buttonType === 'Archive'
                            ? CDN_ASSETS_URL.archiveIcon
                            : buttonType === 'Activate'
                            ? CDN_ASSETS_URL.activateIcon
                            : CDN_ASSETS_URL.warningIng
                    }
                    alt='warning'
                    className='warning-img'
                />
                <CoreTitle tag='h5' text={title}></CoreTitle>
                <p>{description}</p>
            </Modal>
        </>
    );
};

export default ConfirmationModal;
