import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from '@store/store';
import { UserInvitationAPI } from '@service/userInvitation.service';
import {
    IAccessPolicy,
    IAllPolicyKeys,
    IInvitation,
    IInvitationList,
    IInvitationPayload,
    IItemPermission,
    IMembership,
    SubscriptionPolicyKey,
} from '@models/invitation.model';
import { cloneDeep } from 'lodash';
import { setAllBooleanValues } from '@utils/modifyObjectValues';
import { INITIAL_INVITATION_MEMBERSHIP } from '@config/invitationMembership';
import { selectInvitationOrganizations } from './organizationSlice';
import { selectInvitationSubscriptionDevices } from './deviceSlice';

interface IUserInvitationState {
    memberShip: {
        data: any[];
        isLoading: boolean;
    };
    invitations: {
        data: IInvitationList;
        isLoading: boolean;
        next?: string;
        nonce?: string;
    };
    invitation: {
        data: any;
        isLoading: boolean;
    };
    membershipCreation: IMembership;
    isLoadingInviteUser: boolean;
}

const initialState: IUserInvitationState = {
    memberShip: {
        data: [],
        isLoading: false,
    },
    invitations: {
        data: [],
        isLoading: false,
        next: undefined,
        nonce: undefined,
    },
    invitation: {
        data: [],
        isLoading: false,
    },
    isLoadingInviteUser: false,
    membershipCreation: INITIAL_INVITATION_MEMBERSHIP,
};

export const getUserMembership = createAsyncThunk('getUserMembership', async () => {
    try {
        const response = await UserInvitationAPI.getMemberShip();
        return response;
    } catch (error: any) {
        throw error as any;
    }
});

export const getUserInvitations = createAsyncThunk(
    'getUserInvitations',
    async (_, { getState }) => {
        try {
            const state = getState() as RootState;
            const invitation = state.userInvitation.invitations;
            const payload: IInvitationPayload = {
                Next: invitation.next,
                Nonce: invitation.next,
                PageLimit: 10,
            };
            const response = await UserInvitationAPI.getInvitations(payload);
            return response;
        } catch (error: any) {
            throw error as any;
        }
    },
);

export const getUserInvitation = createAsyncThunk(
    'getUserInvitation',
    async (InvitationId: string) => {
        try {
            const response = await UserInvitationAPI.getInvitation(InvitationId);
            return response;
        } catch (error: any) {
            throw error as any;
        }
    },
);

export const invitationUser = createAsyncThunk(
    'invitationUser',
    async (inviteInfo: Partial<IInvitation>, { getState }) => {
        try {
            const state = getState() as RootState;
            const membership = state.userInvitation.membershipCreation;
            const payload: IInvitation = {
                ...inviteInfo,
                membership,
            } as IInvitation;
            const response = await UserInvitationAPI.inviteUser(payload);
            return response;
        } catch (error: any) {
            throw error as any;
        }
    },
);

export const userInvitationMembership = createSlice({
    name: 'payment',
    initialState,
    reducers: {
        resetMembership: (state) => {
            state.membershipCreation = INITIAL_INVITATION_MEMBERSHIP;
        },
        updateSubscriptionMembership: (
            state,
            action: {
                type: string;
                payload: { keyName: SubscriptionPolicyKey; policy: IAccessPolicy };
            },
        ) => {
            let { policy } = action.payload;
            const keyName = action.payload.keyName;
            const previousValue = cloneDeep(state.membershipCreation.subscription[keyName]);
            if (policy.write === true) {
                policy = { ...previousValue, write: true, read: true };
            }

            if ('all' in policy) {
                policy = setAllBooleanValues(previousValue, Boolean(policy.all));
            }

            state.membershipCreation.subscription[keyName] = { ...previousValue, ...policy };
        },
        updateInvoicePolicy: (
            state,
            action: {
                type: string;
                payload: IAccessPolicy;
            },
        ) => {
            const previousValue = cloneDeep(state.membershipCreation.invoicePolicy);
            let policy = action.payload;
            if (policy.write === true) {
                policy = { ...previousValue, write: true, read: true };
            }
            if ('all' in policy) {
                policy = setAllBooleanValues(previousValue, Boolean(policy.all));
            }
            state.membershipCreation.invoicePolicy = { ...previousValue, ...policy };
        },

        updateOrganizationPolicy: (
            state,
            action: {
                type: string;
                payload: IItemPermission;
            },
        ) => {
            let policy = action.payload.policy;
            const { itemId, name } = action.payload;
            const previousPolicy =
                cloneDeep(
                    state.membershipCreation.organizationPermissions.find(
                        (org) => org.itemId === itemId,
                    )?.policy,
                ) || DEFAULT_ORGANIZATION_POLICY;

            if (policy.write === true) {
                policy = { ...previousPolicy, write: true, read: true };
            }

            if ('all' in policy) {
                policy = setAllBooleanValues(previousPolicy, Boolean(policy.all));
            }

            const previousOrganization = state.membershipCreation.organizationPermissions;
            state.membershipCreation.organizationPermissions = upsertItem(previousOrganization, {
                policy: { ...previousPolicy, ...policy },
                itemId,
                name,
            });
        },
        updateDevicePolicy: (
            state,
            action: {
                type: string;
                payload: IItemPermission;
            },
        ) => {
            let policy = action.payload.policy;
            const { itemId, name } = action.payload;
            const previousPolicy =
                cloneDeep(
                    state.membershipCreation.devicePermissions.find(
                        (device) => device.itemId === itemId,
                    )?.policy,
                ) || DEFAULT_DEVICE_POLICY;

            if (policy.update === true) {
                policy = { ...previousPolicy, update: true, read: true };
            }

            if ('all' in policy) {
                policy = setAllBooleanValues(previousPolicy, Boolean(policy.all));
            }

            const previousDevices = state.membershipCreation.devicePermissions;
            state.membershipCreation.devicePermissions = upsertItem(previousDevices, {
                policy: { ...previousPolicy, ...policy },
                itemId,
                name,
            });
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getUserMembership.pending, (state) => {
                state.memberShip.isLoading = true;
            })
            .addCase(getUserMembership.fulfilled, (state, action) => {
                state.memberShip.data = action.payload;
                state.memberShip.isLoading = false;
            })
            .addCase(getUserMembership.rejected, (state) => {
                state.invitations.data = [];
                state.invitations.isLoading = false;
            })

            .addCase(invitationUser.pending, (state) => {
                state.isLoadingInviteUser = true;
            })
            .addCase(invitationUser.fulfilled, (state) => {
                state.isLoadingInviteUser = false;
            })
            .addCase(invitationUser.rejected, (state) => {
                state.isLoadingInviteUser = false;
            })

            .addCase(getUserInvitations.pending, (state) => {
                state.invitations.isLoading = true;
            })
            .addCase(getUserInvitations.fulfilled, (state, action) => {
                state.invitations.data = action.payload.Invitations || [];
                state.invitations.next = action.payload.Next;
                state.invitations.nonce = action.payload.Nonce;
                state.invitations.isLoading = false;
            })
            .addCase(getUserInvitations.rejected, (state) => {
                state.invitations.data = [];
                state.invitations.isLoading = false;
            })

            .addCase(getUserInvitation.pending, (state) => {
                state.invitation.isLoading = true;
            })
            .addCase(getUserInvitation.fulfilled, (state, action) => {
                state.invitation.data = action.payload;
                state.invitation.isLoading = false;
            })
            .addCase(getUserInvitation.rejected, (state) => {
                state.invitation.data = [];
                state.invitation.isLoading = false;
            });
    },
});

export const {
    resetMembership,
    updateSubscriptionMembership,
    updateInvoicePolicy,
    updateOrganizationPolicy,
    updateDevicePolicy,
} = userInvitationMembership.actions;

export const selectMembershipData = (state: RootState) => state.userInvitation.memberShip.data;
export const selectIsLoadingInvite = (state: RootState) => state.userInvitation.isLoadingInviteUser;

export const selectInvitations = (state: RootState) => state.userInvitation.invitations;
export const selectInvitationDetails = (state: RootState) => state.userInvitation.invitation;

export const selectMembershipCreation = (state: RootState) =>
    state.userInvitation.membershipCreation;

export const selectMembershipOrganization = (state: RootState) =>
    state.userInvitation.membershipCreation.organizationPermissions;

export const selectMembershipDevicesPermissions = (state: RootState) =>
    state.userInvitation.membershipCreation.devicePermissions;

export const selectMemberCreationPolicy = createSelector(
    [selectMembershipCreation],
    (memberShipPolicy) => {
        const { subscription, invoicePolicy } = memberShipPolicy;
        const subscriptions = Object.entries(subscription).map(([key, value]) => ({
            keyName: key as IAllPolicyKeys,
            policy: value as IAccessPolicy,
        }));
        const invoicesPolicy = [
            {
                keyName: 'invoicePolicy' as IAllPolicyKeys,
                policy: invoicePolicy as IAccessPolicy,
            },
        ];
        return { subscriptions, invoicesPolicy };
    },
);

export const selectOrganizationPolicy = createSelector(
    [selectMembershipOrganization, selectInvitationOrganizations],
    (membershipOrganization, organizations) => {
        return mergeItemList(organizations, membershipOrganization, DEFAULT_ORGANIZATION_POLICY);
    },
);

export const selectDevicesPolicy = createSelector(
    [selectMembershipDevicesPermissions, selectInvitationSubscriptionDevices],
    (membershipDevices, devices) => {
        return mergeItemList(devices, membershipDevices, DEFAULT_DEVICE_POLICY);
    },
);

const DEFAULT_DEVICE_POLICY = {
    read: false,
    update: false,
    delete: false,
};

const DEFAULT_ORGANIZATION_POLICY = {
    write: false,
    read: false,
};

const mergeItemList = (
    allOrganizations: { itemId: string; name: string }[],
    permissionOrganizations: IItemPermission[],
    defaultPermission: IAccessPolicy,
): IItemPermission[] => {
    return allOrganizations.map((org) => {
        const policy =
            permissionOrganizations.find((perm) => perm.itemId === org.itemId)?.policy ||
            defaultPermission;

        return { ...org, policy };
    });
};

function upsertItem(list: IItemPermission[], newItem: IItemPermission): IItemPermission[] {
    const index = list.findIndex((item) => item.itemId === newItem.itemId);

    if (index !== -1) {
        // Update existing item
        list[index] = { ...list[index], ...newItem };
    } else {
        // Add new item
        list.push({ ...newItem });
    }

    return list;
}

export default userInvitationMembership.reducer;
