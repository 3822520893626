import { IAllPolicyKeys, IMembership, SubscriptionPolicyKey } from '@models/invitation.model';

export const INITIAL_INVITATION_MEMBERSHIP: IMembership = {
    apikeyPolicy: {
        read: true,
    },
    invoicePolicy: {
        read: false,
        write: false,
        update: false,
        share: false,
    },
    subscription: {
        subscription: {
            read: false,
            write: false,
            update: false,
            delete: false,
        },
        invoices: {
            read: false,
            write: false,
            update: false,
            delete: false,
        },
        billings: {
            read: false,
            write: false,
            update: false,
            delete: false,
        },
        payments: {
            read: false,
            write: false,
            delete: false,
        },
        alerts: {
            read: false,
            write: false,
            update: false,
            delete: false,
        },
        coupon: {
            read: false,
            write: false,
            update: false,
            delete: false,
        },
        invitation: {
            read: false,
            write: false,
            update: false,
            delete: false,
        },
        membership: {
            read: false,
            write: false,
            update: false,
            delete: false,
        },
        organization: {
            write: false,
        },
        entity: {
            write: false,
        },
        device: {
            write: false,
        },
    },
    devicePermissions: [],
    organizationPermissions: [],
};

export const SUBSCRIPTION_ACCESS_KEYS_VALUE: { [key in IAllPolicyKeys]: string } = {
    subscription: 'Subscription',
    invoices: 'Subscription Invoices',
    billings: 'Billings',
    payments: 'Payments',
    alerts: 'Alerts',
    coupon: 'Coupon',
    invitation: 'Invitation',
    membership: 'Membership',
    organization: 'Organization',
    entity: 'Entity',
    device: 'Device',
    invoicePolicy: 'E-Invoice',
};
