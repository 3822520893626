import React from 'react';
import './CoreLogo.scss'
import { CDN_ASSETS_URL } from '@utils/cdnAssetsUrl';

interface ICoreLogoProps {
    size: 'large' | 'medium' | 'small';
    isWithName?: boolean;
    LogoWithName?: any;
    Logo?: any;
}

const CoreLogo = (props: ICoreLogoProps) => {
    const {
        size = 'medium',
        isWithName = true,
        LogoWithName = CDN_ASSETS_URL.logoSvg,
        Logo = CDN_ASSETS_URL.logoOnlySvg
    } = props
    return (
        <div className={`core-logo ${size}`}>
            <img className="core-logo-img" src={isWithName ? LogoWithName : Logo} alt="main logo"/>
            {/* {isWithName && <div className="env">{APP_CONFIG.environment}</div>} */}
        </div>
    );
};

export default CoreLogo;

