import React, { FC, useState } from 'react';
import './SetupAuthenticatorApp.scss';
import { CoreButton, CoreTitle } from '@packages';
import { Alert, Input } from 'antd';
import { useNavigate } from 'react-router-dom';
import { SETUP_2FA } from '@service/setup-2FA.service';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '@store/hooks';
import { userSelectRole } from '@store/slices/userSlice';

interface SetupAuthenticatorAppProps {
    qrCodeUrl?: string;
    next(): void;
}

const SetupAuthenticatorApp: FC<SetupAuthenticatorAppProps> = ({ qrCodeUrl, next }) => {
    const { t } = useTranslation();
    const { isAdmin } = useAppSelector(userSelectRole);
    const [value, setValue] = useState<string>('');
    const [isLoadingAPI, setLoading] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');

    const navigate = useNavigate();

    const onSubmitNext = async () => {
        if (!value) {
            return;
        }
        setLoading(true);
        const response = await SETUP_2FA.OtpValidation(value);
        response?.success === true && next();
        response?.success === false && setErrorMessage(response?.error || '');

        console.log(response);
        setLoading(false);
    };

    return (
        <>
            <div className='setup-authenticator-app-wrapper mb-3'>
                <div className='setup-authenticator-app-inner-wrapper'>
                    <CoreTitle tag='h5' text={t('SETUP_AUNTHENTICATOR_APP')} />
                    <p className='setup-authenticator-sub-title'>
                        {t('PASSWORD_MANAGER_LIKE_1_PASSWORD')}
                    </p>

                    <CoreTitle tag='h5' text={t('SCAN_THE_QR_CODE')} />
                    <p className='setup-authenticator-sub-title'>
                        {t('USE_AN_AUTHENTICATOR_APP_OR_BROWSER_EXTENSION_TO_SCAN')}
                    </p>

                    <img className='qr-image' src={qrCodeUrl} alt='QR Code Not Found' />
                    <div className='setup-authenticator-verification-code'>
                        <p className='general-label'>{t('VERIFY_THE_CODE_FROM_THE_APP')}</p>
                        <Input
                            value={value}
                            onChange={(event) => setValue(event.target.value)}
                            className='general-input'
                            placeholder='XXXXXX'
                        />
                        {errorMessage && (
                            <Alert
                                description={errorMessage}
                                type='error'
                                closable
                                className='alert'
                                onClose={() => setErrorMessage('')}
                            />
                        )}
                    </div>
                </div>
            </div>
            <div className='two-factor-auth-step-button-group'>
                <CoreButton
                    onClick={() => navigate(isAdmin ? '/admin/setting' : '/setting')}
                    text={t('CANCEL')}
                />
                <CoreButton
                    loading={isLoadingAPI}
                    disabled={!value}
                    type='primary'
                    onClick={() => onSubmitNext()}
                    text={t('CONTINUE')}
                />
            </div>
        </>
    );
};

export default SetupAuthenticatorApp;
