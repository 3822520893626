import React from 'react';
import './SecurityKey.scss';
import { InfoCircleOutlined } from '@ant-design/icons';
import { CoreButton, CoreTitle } from '@packages';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { onChange2FAActivation, userSelectRole } from '@store/slices/userSlice';
import { useTranslation } from 'react-i18next';

const SecurityKey = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { isAdmin } = useAppSelector(userSelectRole);
    const onSubmit = () => {
        dispatch(onChange2FAActivation(true));
        navigate(isAdmin ? '/admin/setting' : '/setting');
    };
    return (
        <div>
            <div className='security-key-wrapper'>
                <div className='recovery-codes-info'>
                    <div className='info-box'>
                        <div>
                            <InfoCircleOutlined className='info-icons' />
                        </div>
                        <div className='info-box-information'>
                            <p>
                                {t('YOU_HAVE_ENABLED_TWO_FACTOR_AUTHENTICATION_USING')}{' '}
                                <span className='recovery-codes-span'>
                                    {t('AUTHENTICATOR_APP')}
                                </span>
                                .
                            </p>
                        </div>
                    </div>
                </div>
                <CoreTitle
                    className='title-color'
                    tag='h5'
                    text={t('DO_NOT_GET_LOCKED_OUT_CONFIGURE_ADDITIONAL_2FA_METHODS')}
                />
                <div className='tow-fa-sub-title'>
                    {t(
                        'ADDING_A_BACKUP_2FA_METHOD_WILL_HELP_YOU_GAIN_ACCESS_TO_YOUR_ACCOUNT_IN_CASE_YOU_LOSE',
                    )}{' '}
                    <br /> {t('YOUR_DEVICE_AND_DONOT_HAVE_YOUR_RECOVERY_CODES')}{' '}
                </div>
            </div>
            <div className='text-center two-factor-auth-step-button-group '>
                <CoreButton onClick={() => onSubmit()} text={t('DONE')} type='primary' />
            </div>
        </div>
    );
};

export default SecurityKey;
