import { AXIOS_INSTANCE } from '@config/axios-config';
import { APP_CONFIG } from '@config/constants';
import { IAdminCreateRolePayload, IGetRolePayload } from '@models/admin/role.model';
import { processQuery } from '@utils/processQuery';
import { AxiosError } from 'axios';

export const AdminRoleAPI = {
    getResourceList: async (): Promise<any> => {
        const url = APP_CONFIG.identityUrl + `EndpointQuery/EndpointList`;
        try {
            const response = await AXIOS_INSTANCE.get<any>(url);
            return response?.data?.Result;
        } catch (error) {
            const err = error as AxiosError;
            throw err?.response?.data;
        }
    },
    createRole: async (payload: IAdminCreateRolePayload): Promise<any> => {
        const url = APP_CONFIG.identityUrl + `RoleCommand/Create`;
        try {
            const response = await AXIOS_INSTANCE.post<any>(url, payload);
            return response?.data;
        } catch (error) {
            const err = error as AxiosError;
            throw err?.response?.data;
        }
    },
    getRoleByName: async (payload: IGetRolePayload): Promise<any> => {
        const url = APP_CONFIG.identityUrl + `RoleQuery/Role${processQuery(payload)}`;
        try {
            const response = await AXIOS_INSTANCE.get<any>(url);
            return response?.data?.Result;
        } catch (error) {
            const err = error as AxiosError;
            throw err?.response?.data;
        }
    },

    updateRole: async (payload: IAdminCreateRolePayload): Promise<any> => {
        const url = APP_CONFIG.identityUrl + `RoleCommand/Update`;
        try {
            const response = await AXIOS_INSTANCE.post<any>(url, payload);
            return response?.data;
        } catch (error) {
            const err = error as AxiosError;
            throw err?.response?.data;
        }
    },
};
