export const RegexValidators = {
    VAT_REGISTRATION_NUMBER: /^3[(0-9)*]{13}3$/,
    ARABIC_CHARACTER: /^[؀-ۿـ\s]*$/u,
    ARABIC_WITH_ALPHANUMERIC_CHARACTER: /^[؀-ۿa-zA-Z0-9\s]*$/,
    ENGLISH_CHARACTER: /^[a-zA-Z\s]+$/,
    ENGLISH_ARABIC_CHARACTER: /^[\u0600-\u06FFa-zA-Z\s]*$/u,
    SAUDI_MOBILE_NO: /^(966|\+966|05|5)(5|0|3|6|4|9|1|8|7)([0-9]{8})$/,
    EMAIL: /^(?!\.)(?!.*\.\.)(?!.*\.$)[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}$/,
    FAX: /^(\+?\d{1,}(\s?|-?)\d*(\s?|-?)\(?\d{2,}\)?(\s?|-?)\d{3,}\s?\d{3,})$/,
    FOUR_DIGIT: /^[1-9]\d{3}$/,
    FIVE_DIGIT: /^[1-9]\d{4}$/,
    SERIAL_NUMBER: /^1-([^\s|]+)\|2-([^\s|]+)\|3-([^\s|]+)$/,
    PASSWORD: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#?!@$%^&-])[a-zA-Z\d#?!@$%^&-]{10}$/,
    UPPERCASE_LOWERCASE: /^(?=.*[a-z])(?=.*[A-Z]).+$/,
    ONLY_ONE_NUMBER: /\d+/,
    GRATER_THEN_ZERO: /^(?=.*[1-9])\d*(?:\.\d+)?$/,
    ALLOW_POSITIVE_NUMBER: /^(0|[1-9]\d*)(\.\d+)?$/,
    ONLY_NUMERIC: /^([0-9]*)?$/,
    TIN_NUMBER: /^(\d{10})$/,
    OTP_NUMBER: /^(\d{6})$/,
    SPECIAL_CHARACTER: /[#?!@$%^&-]/,
    DATE_VALIDATION: /^\d{2}\/\d{2}$/,
    NUMERIC_WITH_PLUS: /^\+?[0-9]*$/,
    ALPHA_NUMERIC: /^[a-zA-Z0-9]+$/,
    ALPHA_NUMERIC_WITH_SPACE: /^[a-zA-Z0-9 ]+$/,
    IP_ADDRESS:
        /^(?:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)\.){3}(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(?:\/(?:3[0-2]|[12]?\d))?$/,
    WEBSITE: /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(:\d+)?(\/[^\s]*)?$/,
    REAL_NUMBER: /^-?\d+(\.\d+)?$/,
};
