import { Navigate, Route, Routes } from 'react-router-dom';
import React, { Suspense } from 'react';
import { Spin } from 'antd';
import { UserRoles } from '@config/user-roles';

const RecoverAccount = React.lazy(
    () => import('../client-features/auth/recover-account/RecoverAccount'),
);

const Login = React.lazy(() => import('../client-features/auth/login/Login'));

const SignUp = React.lazy(() => import('../client-features/auth/sign-up/SignUp'));

const SignUpConfirmation = React.lazy(
    () => import('../client-features/auth/sign-up-confirmation/SignUpConfirmation'),
);

const TwoStepVerification = React.lazy(
    () => import('../client-features/auth/two-step-verification/TwoStepVerification'),
);

const VerifyAccount = React.lazy(
    () => import('../client-features/auth/verify-account/VerifyAccount'),
);

const ResetPassword = React.lazy(
    () => import('../client-features/auth/reset-password/ResetPassword'),
);

const Maintenance = React.lazy(() => import('../client-features/maintenance/Maintenance'));
import { RequireAnonymous, RequireAuth } from '@components';

const ActivateAccount = React.lazy(
    () => import('../client-features/auth/activate-account/ActivateAccount'),
);

const ContactUsView = React.lazy(
    () => import('../common-features/contact-us/pages/contact-us-view/ContactUsView'),
);
const EnterpriseContactRequest = React.lazy(
    () => import('../common-features/enterprise-contact-us/pages/EnterpriseContactRequest'),
);

const ResetPasswordConfirmation = React.lazy(
    () => import('../client-features/auth/reset-password/ResetPasswordConfirmation'),
);

const SsoInit = React.lazy(() => import('@client-features/auth/sso/sso-init/SsoInit'));
const SsoView = React.lazy(() => import('@client-features/auth/sso/sso-view/SsoView'));

const Authorization = React.lazy(() => import('../client-features/authorization/Authorization'));
const AcceptInvitation = React.lazy(
    () => import('../client-features/auth/accept-invitation/AcceptInvitation'),
);

const Home = React.lazy(() => import('./../home/Home'));

import AdminRouter from './Admin-Router';
import ClientRouter from './Client-Router';
import LazySlider from '@components/custom-loader/LazySlider';

const AppRouter = () => {
    return (
        <Routes>
            <Route
                path='/'
                element={
                    <Suspense fallback={<LazySlider />}>
                        <Home />
                    </Suspense>
                }
            ></Route>
            <Route path='authorization' element={<Authorization />}></Route>
            <Route
                path='contact-us'
                element={
                    <Suspense fallback={<LazySlider />}>
                        <ContactUsView />
                    </Suspense>
                }
            ></Route>

            <Route
                path='enterprise-contact'
                element={
                    <Suspense fallback={<LazySlider />}>
                        <EnterpriseContactRequest />
                    </Suspense>
                }
            ></Route>

            <React.Fragment>
                <Route
                    element={
                        <RequireAuth
                            allowedRoles={[
                                UserRoles.organizationAdmin,
                                UserRoles.organizationMember,
                            ]}
                        />
                    }
                >
                    <Route path='*' element={<ClientRouter />} />
                </Route>

                <Route element={<RequireAuth allowedRoles={[UserRoles.admin]} />}>
                    <Route path='admin/*' element={<AdminRouter />} />
                </Route>
            </React.Fragment>

            <Route path='*' element={<Navigate replace to='/' />}></Route>

            <Route element={<RequireAnonymous />}>
                <Route
                    path='sign-up'
                    element={
                        <Suspense fallback={<LazySlider />}>
                            <SignUp />
                        </Suspense>
                    }
                ></Route>
                <Route
                    path='sign-up-confirmation'
                    element={
                        <Suspense fallback={<LazySlider />}>
                            <SignUpConfirmation />
                        </Suspense>
                    }
                ></Route>
                <Route
                    path='verify-account'
                    element={
                        <Suspense fallback={<LazySlider />}>
                            <VerifyAccount />
                        </Suspense>
                    }
                ></Route>
                <Route
                    path='login'
                    element={
                        <Suspense fallback={<LazySlider />}>
                            <Login />
                        </Suspense>
                    }
                ></Route>
                <Route
                    path='recover-account'
                    element={
                        <Suspense fallback={<LazySlider />}>
                            <RecoverAccount />
                        </Suspense>
                    }
                ></Route>
                <Route
                    path='activate-account'
                    element={
                        <Suspense fallback={<LazySlider />}>
                            <ActivateAccount />
                        </Suspense>
                    }
                ></Route>
                <Route
                    path='reset-password'
                    element={
                        <Suspense fallback={<LazySlider />}>
                            <ResetPassword />
                        </Suspense>
                    }
                ></Route>
                <Route
                    path='accept-invitation'
                    element={
                        <Suspense fallback={<LazySlider />}>
                            <AcceptInvitation />
                        </Suspense>
                    }
                ></Route>
                <Route
                    path='reset-password-confirmation'
                    element={
                        <Suspense fallback={<LazySlider />}>
                            <ResetPasswordConfirmation />
                        </Suspense>
                    }
                ></Route>
                <Route
                    path='maintenance'
                    element={
                        <Suspense fallback={<LazySlider />}>
                            <Maintenance />
                        </Suspense>
                    }
                ></Route>
                <Route
                    path='two-step-verification'
                    element={
                        <Suspense fallback={<LazySlider />}>
                            <TwoStepVerification />
                        </Suspense>
                    }
                ></Route>
                <Route
                    path='sso-init'
                    element={
                        <Suspense fallback={<LazySlider />}>
                            <SsoInit />
                        </Suspense>
                    }
                ></Route>
                <Route
                    path='sso'
                    element={
                        <Suspense fallback={<LazySlider />}>
                            <SsoView />
                        </Suspense>
                    }
                ></Route>
            </Route>
        </Routes>
    );
};

export default AppRouter;
