import { useEffect, useState } from 'react';
import { RightOutlined, LeftOutlined } from '@ant-design/icons';
import { Layout } from 'antd';
import { CoreLogo, CoreIconButton } from '@packages';
import './LayoutWrapper.scss';
import { Outlet } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import NavigationBar from './navigation-bar/NavigationBar';
import MenuItems from '@components/main-layout/menu-items/MenuItems';
import { useCheckAuthorization } from '@hooks';
import { SIDEBAR_COLLAPSE } from '@config/constants';
import { localStoreAPI } from '@service/local-storage.service';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { userSelectRole } from '@store/slices/userSlice';
import { getSubscription } from '@store/slices/subscribeSlice';
const { Sider, Content } = Layout;

export const LayoutWrapper = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [collapsed, setCollapsed] = useState(false);
    const checkAuthorization = useCheckAuthorization();
    const { isOrganizationAdmin, isOrganizationMember, isAdmin } = useAppSelector(userSelectRole);

    useEffect(() => {
        checkAuthorization().then();
        if (isOrganizationAdmin || isOrganizationMember) {
            dispatch(getSubscription());
        }

        setCollapsed(localStoreAPI.getItemLocalStore(SIDEBAR_COLLAPSE) === 'true' ? true : false);
    }, []);

    const handleCollapse = () => {
        setCollapsed(!collapsed);
        localStoreAPI.setItemLocalStore(SIDEBAR_COLLAPSE, !collapsed);
    };

    const redirectHome = () => {
        isAdmin ? navigate('/admin/iam/users') : navigate('/dashboard');
    };

    return (
        <div className='main-layout-wrapper'>
            <Layout>
                <Sider theme='light' width={280} trigger={null} collapsible collapsed={collapsed}>
                    <div className='logo-wrapper' onClick={redirectHome} aria-hidden='true'>
                        <CoreLogo size={'medium'} isWithName={!collapsed} />
                    </div>
                    <div className='collapsed-button' onClick={handleCollapse} aria-hidden='true'>
                        <CoreIconButton
                            variant='round'
                            icon={collapsed ? <RightOutlined /> : <LeftOutlined />}
                        />
                    </div>
                    <div>
                        <MenuItems />
                    </div>
                </Sider>
                <Layout className='site-layout'>
                    <div className='site-layout-navbar'>
                        <NavigationBar />
                    </div>
                    <Content className='layout-content'>
                        <Outlet />
                    </Content>
                </Layout>
            </Layout>
        </div>
    );
};

export default LayoutWrapper;
