import { IGetRolePayload } from '@models/admin/role.model';
import { IRole } from '@models/role.model';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AdminRoleAPI } from '@service/admin/role.service';
import { RootState } from '@store/store';

export interface IAdminRoleState {
    resourceList: {
        data: [];
        isLoading: boolean;
    };
    role: {
        data: IRole | null;
        isLoading: boolean;
    };
}

const initialState: IAdminRoleState = {
    resourceList: {
        data: [],
        isLoading: false,
    },
    role: {
        data: null,
        isLoading: false,
    },
};

export const getResourceList = createAsyncThunk('getResourceList', async () => {
    try {
        const response = await AdminRoleAPI.getResourceList();
        return response;
    } catch (error: any) {
        throw error?.response?.data?.Message || error?.message;
    }
});

export const getRoleByName = createAsyncThunk('getRoleByName', async (payload: IGetRolePayload) => {
    try {
        const response = await AdminRoleAPI.getRoleByName(payload);
        return response;
    } catch (error: any) {
        throw error?.response?.data?.Message || error?.message;
    }
});

const adminRoleSlice = createSlice({
    name: 'admin-role',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getResourceList.pending, (state) => {
                state.resourceList.isLoading = true;
            })
            .addCase(getResourceList.fulfilled, (state, action) => {
                state.resourceList.isLoading = false;
                state.resourceList.data = action.payload;
            })
            .addCase(getResourceList.rejected, (state) => {
                state.resourceList.isLoading = false;
                state.resourceList.data = [];
            })

            .addCase(getRoleByName.pending, (state) => {
                state.role.isLoading = true;
            })
            .addCase(getRoleByName.fulfilled, (state, action) => {
                state.role.isLoading = false;
                state.role.data = action.payload;
            })
            .addCase(getRoleByName.rejected, (state) => {
                state.role.isLoading = false;
                state.role.data = null;
            });
    },
});

export const roleDetails = (state: RootState) => state.adminRole.role;

export default adminRoleSlice.reducer;
