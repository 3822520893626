import { AXIOS_INSTANCE } from '@config/axios-config';
import { APP_CONFIG } from '@config/constants';
import {
    ICreateRolePayload,
    IDeleteRolePayload,
    IRole,
    IRoleGetPayload,
    IRoleResponse,
} from '@models/role.model';
import { processQuery } from '@utils/processQuery';
import { AxiosError } from 'axios';

export const RoleAPI = {
    getRoles: async (payload: IRoleGetPayload): Promise<IRoleResponse> => {
        const url = APP_CONFIG.identityUrl + `RoleCommand/Roles`;
        try {
            const response = await AXIOS_INSTANCE.post<any>(url, payload);
            return response?.data as IRoleResponse;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },

    getRoleById: async (RoleId: string): Promise<IRole> => {
        const url = APP_CONFIG.identityUrl + `RoleQuery/Role/${processQuery({ RoleId })}`;
        try {
            const response = await AXIOS_INSTANCE.post<any>(url);
            return response?.data?.Result as IRole;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },

    createRole: async (payload: ICreateRolePayload): Promise<any> => {
        const url = APP_CONFIG.identityUrl + `RoleCommand/Create`;
        try {
            const response = await AXIOS_INSTANCE.post<any>(url, payload);
            return response;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },

    deleteRole: async (payload: IDeleteRolePayload): Promise<any> => {
        const url = APP_CONFIG.identityUrl + `RoleCommand/Delete`;
        try {
            const response = await AXIOS_INSTANCE.post<any>(url, payload);
            return response?.data?.Result;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },
};
