import React, { MouseEventHandler } from "react";
import { Image } from "antd";
import { CoreBackButton, CoreButton, CoreTitle } from "../../packages";
import './CoreStaticPage.scss'
import { CDN_ASSETS_URL } from "@utils/cdnAssetsUrl";

interface ICoreButtonProps {
    title: string;
    subTitle: string;
    imageSrc?: string;
    imageWidth?: string;
    imageHeight?: string;
    linkTo?: string;
    linkForm?: string;
    onClick?: (MouseEventHandler<HTMLAnchorElement> & MouseEventHandler<HTMLButtonElement>) | undefined;
    isInnerPage?: boolean;
}

const CoreStaticPage = (props: ICoreButtonProps) => {

    const {
        title = '',
        subTitle = '',
        imageSrc,
        imageWidth,
        imageHeight,
        linkTo,
        linkForm,
        isInnerPage
    } = props

    return (
        <div className={`core-static-wrapper ${isInnerPage ? 'inner-page' : ''}`}>
            <div className="core-static-wrapper-content">
                <div className="core-static-image">
                    <Image width={`${imageWidth ? imageWidth : ''}`} height={`${imageHeight ? imageHeight : ''}`}
                           src={`${imageSrc ? imageSrc : CDN_ASSETS_URL.featuredIconPng}`} preview={false} />
                </div>
                <CoreTitle tag="h1" text={title} />
                <p className="core-static-sub-title">{subTitle}</p>
                <div className="core-static-navigation">
                    <CoreBackButton linkTo={linkForm} isRound={false} />
                    <CoreButton text='Take me home' type='primary' linkTo={linkTo} />
                </div>
            </div>
        </div>

    );
};

export default CoreStaticPage;