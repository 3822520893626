import { RequestStatus } from '@models/async-status.enum';
import { IUpdateUser, IUser, IUserGetPayload, IUserTable } from '@models/user.model';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { localStoreAPI } from '@service/local-storage.service';
import { UserAPI } from '@service/user.service';
import { RootState } from '@store/store';
import { isNil, omitBy } from 'lodash';
import { UserRoles, UserRolesValues } from '@config/user-roles';

interface IUserState {
    status: RequestStatus;
    userDetails: IUser;
    isActive2Fa: boolean;
    users: {
        status: RequestStatus;
        data: IUserTable[];
        Next?: string;
        Nonce?: string;
    };
    userDetailsByEmail: IUser | null;
}

const initialState: IUserState = {
    status: 'idle',
    userDetails: localStoreAPI.getLocalStorageLoggedUserProfile(),
    isActive2Fa: localStoreAPI.getLocalStorageLoggedUserProfile()?.Is2FaEnabled ?? false,
    users: {
        status: 'idle',
        data: [],
        Next: undefined,
        Nonce: undefined,
    },
    userDetailsByEmail: null,
};

export const getUserDetails = createAsyncThunk('getUserDetails', async () => {
    try {
        return UserAPI.GetUserDetails();
    } catch (error: any) {
        throw error as any;
    }
});
export const getUsers = createAsyncThunk('getUsers', async (payload: IUserGetPayload) => {
    try {
        const users = await UserAPI.getUsers(payload);
        return {...users, isConcat: payload.isConcat};
    } catch (error: any) {
        throw error as any;
    }
});

export const UpdateLoggedUserRequest = createAsyncThunk(
    'updateUserData',
    async (user: IUpdateUser) => {
        try {
            const response = await UserAPI.updateLoggedUserInfo(user);
            return user;
        } catch (error: any) {
            throw error as any;
        }
    },
);

export const getUserByMail = createAsyncThunk(
    'getUserByEmail',
    async (payload: { Email: string }) => {
        try {
            return UserAPI.getUserByMail(payload);
        } catch (error: any) {
            throw error as any;
        }
    },
);

export const User = createSlice({
    name: 'user',
    initialState,
    reducers: {
        onChange2FAActivation: (state, action: { type: string; payload: boolean }) => {
            state.isActive2Fa = action.payload;
        },
        onChangeUserProfile: (state, action: { type: string; payload: string }) => {
            state.userDetails.DisplayPictureUrl = action.payload;
            const prevData: IUser = {...state.userDetails, DisplayPictureUrl: action.payload};
            localStoreAPI.setLocalStorageLoggedUserProfile(prevData);
        },
        onClearUserInfo: (state) => {
            state.userDetails = {} as any;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getUserDetails.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getUserDetails.rejected, (state) => {
                state.status = 'failed';
            })
            .addCase(getUserDetails.fulfilled, (state, action) => {
                state.status = 'idle';
                state.userDetails = action.payload;
                state.isActive2Fa = !!action.payload.Is2FaEnabled;
                localStoreAPI.setLocalStorageLoggedUserProfile(action.payload);
            })
            .addCase(UpdateLoggedUserRequest.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(UpdateLoggedUserRequest.rejected, (state) => {
                state.status = 'failed';
            })
            .addCase(UpdateLoggedUserRequest.fulfilled, (state, action) => {
                const response = omitBy(action.payload, isNil);
                const prevData = {...state.userDetails, ...response};
                state.status = 'idle';
                state.userDetails = prevData as IUser;
                localStoreAPI.setLocalStorageLoggedUserProfile(prevData);
            })

            .addCase(getUsers.pending, (state) => {
                state.users.status = 'loading';
            })
            .addCase(getUsers.rejected, (state) => {
                state.users.status = 'failed';
                state.users.data = [];
            })
            .addCase(getUsers.fulfilled, (state, action) => {
                state.users.status = 'idle';
                const {Users = [], Next, Nonce, isConcat} = action.payload;
                const hasNext = !!Next;
                state.users.data = isConcat ? state.users.data.concat(Users) : Users;
                state.users.status = 'idle';
                state.users.Next = Next;
                state.users.Nonce = hasNext ? Nonce : undefined;
            })

            .addCase(getUserByMail.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getUserByMail.rejected, (state) => {
                state.status = 'failed';
            })
            .addCase(getUserByMail.fulfilled, (state, action) => {
                state.status = 'idle';
                state.userDetailsByEmail = action.payload;
            });
    },
});

export const {onChange2FAActivation, onChangeUserProfile, onClearUserInfo} = User.actions;

export const selectLoggedUserInfo = (state: RootState) => state.user;
export const selectUser2FaActivity = (state: RootState) => state.user.isActive2Fa;

export const userSelectRole = (
    state: RootState,
): {
    name: UserRolesValues;
    isAdmin: boolean;
    isAppUser: boolean;
    isOrganizationAdmin: boolean;
    isOrganizationMember: boolean;
} => {
    const roles = state?.user?.userDetails?.Roles || [];
    if (roles.some((data) => data.RoleName === UserRoles.organizationAdmin)) {
        return {
            name: UserRoles.organizationAdmin,
            isAdmin: false,
            isAppUser: false,
            isOrganizationAdmin: true,
            isOrganizationMember: false,
        };
    }
    if (roles.some((data) => data.RoleName === UserRoles.organizationMember)) {
        return {
            name: UserRoles.appuser,
            isAdmin: false,
            isAppUser: true,
            isOrganizationAdmin: false,
            isOrganizationMember: true,
        };
    }
    if (roles.some((data) => data.RoleName === UserRoles.admin)) {
        return {
            name: UserRoles.admin,
            isAdmin: true,
            isAppUser: false,
            isOrganizationAdmin: false,
            isOrganizationMember: false,
        };
    }
    if (roles.some((data) => data.RoleName === UserRoles.appuser)) {
        return {
            name: UserRoles.appuser,
            isAdmin: false,
            isAppUser: true,
            isOrganizationAdmin: false,
            isOrganizationMember: false,
        };
    }
    return {
        name: UserRoles.anonymous,
        isAdmin: false,
        isAppUser: false,
        isOrganizationAdmin: false,
        isOrganizationMember: false,
    };
};

export default User.reducer;
