import { useState } from 'react';
import './OrganizationNotification.scss';
import { CoreTitle } from '@packages';
import { Button, Divider, Image } from 'antd';
import { CDN_ASSETS_URL } from '@utils/cdnAssetsUrl';

const OrganizationNotification = () => {
    const orgNotificationDataLength = 2;
    const [orgNotificationData, setOrgNotificationData] = useState([
        {
            id: 1,
            message: 'Auto layout is a constraint-based layout system to create an adaptive UI.',
            isRead: false,
        },
        {
            id: 2,
            message: 'Auto layout is a constraint-based layout system to create an adaptive UI.',
            isRead: true,
        },
        {
            id: 3,
            message: 'Auto layout is a constraint-based layout system to create an adaptive UI.',
            isRead: false,
        },
    ]);

    const markAsRead = (id: any) => {
        const updatedNotifications = orgNotificationData.map((notification) =>
            notification.id === id ? { ...notification, isRead: true } : notification,
        );
        setOrgNotificationData(updatedNotifications);
    };

    const markAllReadHandel = () => {
        const updatedNotifications = orgNotificationData.map((notification) => {
            return {
                ...notification,
                isRead: true,
            };
        });
        setOrgNotificationData(updatedNotifications);
    };

    return (
        <div className='organization-notification-wrapper'>
            {orgNotificationDataLength < 1 ? (
                <div className='notification-null'>
                    <div>
                        <Image
                            className='notification-null-image'
                            width={143}
                            height={127}
                            src={CDN_ASSETS_URL.noNotification}
                            preview={false}
                        />
                        <div className='notification-null-details'>
                            <CoreTitle tag='h4' text='No notifications' />
                            <p>
                                There are no notifications <br />
                                at the moment.
                            </p>
                        </div>
                    </div>
                </div>
            ) : (
                <div>
                    {orgNotificationData.map((item) => (
                        <div
                            className='notification-item'
                            key={item.id}
                            onClick={() => markAsRead(item.id)}
                        >
                            <div className='item-box'>
                                <p
                                    className={
                                        item.isRead
                                            ? 'notification-header'
                                            : 'active-notification-header'
                                    }
                                >
                                    New notification <span className='date-span'>(12/06/2012)</span>
                                </p>
                                <p className='notification-body'>{item.message}</p>
                            </div>
                            <div
                                className='notification-circle'
                                style={{ display: item.isRead ? 'none' : 'block' }}
                            />
                        </div>
                    ))}
                    <Divider className='notification-divider' />
                    <div className='notification-button-wrapper'>
                        <Button className='action-button'>See all</Button>
                        <Button className='action-button' onClick={markAllReadHandel}>
                            Mark all read
                        </Button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default OrganizationNotification;
