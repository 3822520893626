import { Layout } from 'antd';
import './SubscribeLayoutWrapper.scss';
import { CoreLogo } from '@packages';
import { useNavigate } from 'react-router-dom';
import LanguageMenu from '@components/main-layout/layout-wrapper/LanguageMenu';
import { useAppSelector } from '@store/hooks';
import { userSelectRole } from '@store/slices/userSlice';

const { Header } = Layout;

interface ISubscribeLayoutWrapper {
    children: React.ReactNode;
}

const SubscribeLayoutWrapper = (props: ISubscribeLayoutWrapper) => {
    const { children } = props;
    const navigate = useNavigate();
    const { isAdmin } = useAppSelector(userSelectRole);

    const redirectHome = () => {
        isAdmin ? navigate('/admin/iam/users') : navigate('/dashboard');
    };

    return (
        <div className='subscribe-layout-wrapper'>
            <div className='subscribe-layout-header'>
                <Layout className='site-layout'>
                    <Header className='layout-header'>
                        <div className='logo-wrapper' onClick={redirectHome}>
                            <CoreLogo size={'medium'} />
                        </div>
                        <LanguageMenu />
                    </Header>
                    <div className='subscribe-children'>{children}</div>
                </Layout>
            </div>
        </div>
    );
};

export default SubscribeLayoutWrapper;
