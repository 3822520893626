import { RequestStatus } from '@models/async-status.enum';
import { IUploadCSVConfigs, IUploadCSVDetails } from '@models/upload-csv';
import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { INVOICE_API } from '@service/invoice.service';
import { RootState } from '@store/store';

interface IInvoiceMappingState {
    mappingPayload: any;
    isLoading: boolean;
    headers: string[];
    referenceId: string | null;
    uploadCsvConfig: {
        data: IUploadCSVConfigs;
        status: RequestStatus;
    };
    uploadCsvConfigDetails: {
        data: IUploadCSVDetails;
        status: RequestStatus;
    };
    ConfigId: string;
    uploadInvoicePayload: {
        data: any;
        file: any;
    };
}

const initialState: IInvoiceMappingState = {
    mappingPayload: null,
    isLoading: false,
    headers: [],
    referenceId: null,
    uploadCsvConfig: {
        data: [],
        status: 'idle',
    },
    uploadCsvConfigDetails: {
        data: {} as IUploadCSVDetails,
        status: 'idle',
    },
    ConfigId: '',
    uploadInvoicePayload: {
        data: null,
        file: null,
    },
};

export const uploadCSVFile = createAsyncThunk(
    'uploadCSVFile',
    async ({
        file,
        data,
    }: {
        file: File;
        data: { OrganizationId: string; DeviceId: string; EntityId: string };
    }) => {
        try {
            const response = await INVOICE_API.uploadCSVFile(file, data);
            return response;
        } catch (error) {
            throw error as any;
        }
    },
);

export const uploadCSVConfigFiles = createAsyncThunk('uploadCSVConfigFiles', async () => {
    try {
        const response = await INVOICE_API.getUploadCSVFiles();
        return response;
    } catch (error) {
        throw error as any;
    }
});

export const saveMapping = createAsyncThunk('saveMapping', async (payload: any) => {
    try {
        const response = await INVOICE_API.saveMapping(payload);
        return response;
    } catch (error) {
        throw error as any;
    }
});

export const uploadCSVConfigFileById = createAsyncThunk(
    'uploadCSVConfigFileById',
    async (mapConfigId: string) => {
        try {
            const response = await INVOICE_API.getUploadCSVFileById(mapConfigId);
            return response.Result as IUploadCSVDetails;
        } catch (error) {
            throw error as any;
        }
    },
);

export const invoiceMappingSlice = createSlice({
    name: 'invoiceMappingSlice',
    initialState,
    reducers: {
        setMappingPayload(state, action: PayloadAction<any>) {
            state.mappingPayload = action.payload;
        },
        setUploadInvoiceForm: (state, action: PayloadAction<any>) => {
            state.uploadInvoicePayload = {
                ...state.uploadInvoicePayload,
                data: action.payload.data,
                file: action.payload.file,
            };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(uploadCSVFile.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(uploadCSVFile.fulfilled, (state, action) => {
                state.isLoading = false;
                state.headers = action.payload?.Headers || [];
                state.referenceId = action.payload?.ReferenceId || null;
            })
            .addCase(uploadCSVFile.rejected, (state) => {
                state.isLoading = false;
            })

            .addCase(uploadCSVConfigFiles.pending, (state) => {
                state.uploadCsvConfig.status = 'loading';
            })
            .addCase(uploadCSVConfigFiles.fulfilled, (state, action) => {
                state.uploadCsvConfig.data = action.payload?.Result || [];
                state.uploadCsvConfig.status = 'idle';
            })
            .addCase(uploadCSVConfigFiles.rejected, (state) => {
                state.uploadCsvConfig.status = 'failed';
            })

            .addCase(uploadCSVConfigFileById.pending, (state) => {
                state.uploadCsvConfigDetails.status = 'loading';
            })
            .addCase(uploadCSVConfigFileById.fulfilled, (state, action) => {
                state.uploadCsvConfigDetails.data = action.payload;
                state.uploadCsvConfigDetails.status = 'idle';
            })
            .addCase(uploadCSVConfigFileById.rejected, (state) => {
                state.uploadCsvConfigDetails.status = 'failed';
            })

            .addCase(saveMapping.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(saveMapping.fulfilled, (state, action) => {
                state.isLoading = false;
                state.ConfigId = action.payload?.Result;
            })
            .addCase(saveMapping.rejected, (state) => {
                state.isLoading = false;
            });
    },
});

export const mapReferenceId = (state: RootState) => state.invoiceMapping.referenceId;
export const mapConfigId = (state: RootState) => state.invoiceMapping.ConfigId;
export const selectHeaderOptions = createSelector(
    (state: RootState) => state.invoiceMapping.headers,
    (headers) =>
        headers.map((header) => ({
            label: header,
            value: header,
        })),
);

export const selectGetUploadCsvConfigs = (state: RootState) =>
    state.invoiceMapping?.uploadCsvConfig;
export const selectUploadCsvConfigDetails = (state: RootState) =>
    state.invoiceMapping.uploadCsvConfigDetails;

export const selectGetUploadCsvConfigOptions = createSelector(
    [selectGetUploadCsvConfigs],
    (uploadConfig) => {
        const { data, status } = uploadConfig;
        return {
            data: data.map((newData) => ({
                value: newData.MapConfigId,
                label: newData.MapConfigurationName,
            })),
            isLoading: status === 'loading',
        };
    },
);

export const { setMappingPayload, setUploadInvoiceForm } = invoiceMappingSlice.actions;

export default invoiceMappingSlice.reducer;
