import { DEFAULT_EMPTY_VALUE } from '@config/constants';
import { RequestStatus } from '@models/async-status.enum';
import { IDevice, IDeviceIdPayload } from '@models/organization.model';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { DeviceAPI } from '@service/device.service';
import { RootState } from '@store/store';

interface IDeviceState {
    activeDevices: {
        status: RequestStatus;
        data: IDevice[];
    };
    devices: {
        isLoading: boolean;
        data: IDevice[];
    };
    device: {
        isLoading: boolean;
        data?: IDevice;
    };
    entityDevices: {
        isLoading: boolean;
        data: IDevice[];
    };
    subscriptionDevices: {
        isLoading: boolean;
        data: IDevice[];
    };
}

const initialState: IDeviceState = {
    activeDevices: {
        status: 'idle',
        data: [],
    },
    devices: {
        isLoading: false,
        data: [],
    },
    device: {
        isLoading: false,
        data: undefined,
    },
    entityDevices: {
        isLoading: false,
        data: [],
    },
    subscriptionDevices: {
        isLoading: false,
        data: [],
    },
};

export const getActiveDevices = createAsyncThunk('active-devices', async (orgId: string) => {
    try {
        const response = await DeviceAPI.getActiveDevices(orgId);
        return response;
    } catch (error: any) {
        throw error as any;
    }
});

export const getDevice = createAsyncThunk('organization/device', async (deviceId: string) => {
    try {
        const response = await DeviceAPI.getDevice(deviceId);
        return response.Result;
    } catch (error: any) {
        throw error?.response?.Message || error?.message;
    }
});
export const getDevices = createAsyncThunk(
    'organization/devices',
    async (organizationId: string) => {
        try {
            const response = await DeviceAPI.getDevices(organizationId);
            return response;
        } catch (error: any) {
            console.log(error);
            throw error?.response?.Message || error?.message;
        }
    },
);

export const getEntityDevices = createAsyncThunk(
    'organization/entityDevices',
    async ({ organizationId, entityId }: { organizationId: string; entityId: string }) => {
        try {
            const response = await DeviceAPI.getEntityDevices(organizationId, entityId);
            return response;
        } catch (error: any) {
            console.log(error);
            throw error?.response?.Message || error?.message;
        }
    },
);

export const getSubscriptionDevices = createAsyncThunk(
    'organization/subscriptionDevices',
    async () => {
        try {
            const response = await DeviceAPI.getSubscriptionDevices();
            return response;
        } catch (error: any) {
            throw error as any;
        }
    },
);

export const enableDevice = createAsyncThunk(
    'organization/enable-device',
    async (payload: IDeviceIdPayload) => {
        try {
            const response = await DeviceAPI.enableDevice(payload);
            return response.Result;
        } catch (error: any) {
            throw error?.response?.Message || error?.message;
        }
    },
);
export const disableDevice = createAsyncThunk(
    'organization/disable-device',
    async (payload: IDeviceIdPayload) => {
        try {
            const response = await DeviceAPI.disableDevice(payload);
            return response.Result;
        } catch (error: any) {
            throw error?.response?.Message || error?.message;
        }
    },
);
export const deviceSlice = createSlice({
    name: 'Device',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getActiveDevices.pending, (state) => {
                state.activeDevices.status = 'loading';
            })
            .addCase(getActiveDevices.rejected, (state) => {
                state.activeDevices.status = 'failed';
                state.activeDevices.data = [];
            })
            .addCase(getActiveDevices.fulfilled, (state, action) => {
                state.activeDevices.status = 'idle';
                state.activeDevices.data = action.payload;
            })
            .addCase(getSubscriptionDevices.pending, (state) => {
                state.subscriptionDevices.isLoading = true;
            })
            .addCase(getSubscriptionDevices.rejected, (state) => {
                state.subscriptionDevices.isLoading = false;
                state.subscriptionDevices.data = [];
            })
            .addCase(getSubscriptionDevices.fulfilled, (state, action) => {
                state.subscriptionDevices.isLoading = false;
                state.subscriptionDevices.data = action.payload;
            })
            .addCase(getDevice.pending, (state) => {
                state.devices.isLoading = true;
            })
            .addCase(getDevice.fulfilled, (state, action) => {
                state.device.isLoading = false;
                state.device.data = action.payload || undefined;
            })
            .addCase(getDevice.rejected, (state) => {
                state.device.isLoading = false;
                state.device.data = undefined;
            })
            .addCase(getDevices.pending, (state) => {
                state.devices.isLoading = true;
            })
            .addCase(getDevices.fulfilled, (state, action) => {
                state.devices.isLoading = false;
                state.devices.data = action.payload;
            })
            .addCase(getDevices.rejected, (state) => {
                state.devices.isLoading = false;
                state.devices.data = [];
            })
            .addCase(getEntityDevices.pending, (state) => {
                state.entityDevices.isLoading = true;
            })
            .addCase(getEntityDevices.fulfilled, (state, action) => {
                state.entityDevices.isLoading = false;
                state.entityDevices.data = action.payload;
            })
            .addCase(getEntityDevices.rejected, (state) => {
                state.entityDevices.isLoading = false;
                state.entityDevices.data = [];
            });
    },
});

export const selectActiveDeviceInfo = (state: RootState) => ({
    ...state.device.activeDevices,
    data: state.device.activeDevices.data.map((device) => ({
        ...device,
        value: device?.DeviceId || '',
        label: device?.DeviceName || DEFAULT_EMPTY_VALUE,
    })),
});
export const selectSubscriptionDevicesInfo = (state: RootState) => ({
    ...state.device.subscriptionDevices,
    data: state.device.subscriptionDevices.data.map((device) => ({
        ...device,
        value: device?.DeviceId || '',
        label: device?.DeviceName || DEFAULT_EMPTY_VALUE,
    })),
});

export const selectedEntityDeviceInfo = (state: RootState) => ({
    ...state.device.entityDevices,
    data: state.device.entityDevices.data.map((device) => ({
        ...device,
        value: device?.DeviceId || '',
        label: device?.DeviceName || DEFAULT_EMPTY_VALUE,
    })),
});

export const selectInvitationSubscriptionDevices = (state: RootState) => {
    const devices = state.device.subscriptionDevices.data;
    return devices.map((device) => ({
        itemId: device.DeviceId || '',
        name: device.DeviceName || '',
    }));
};

export const selectDevicesInfo = (state: RootState) => state.device.devices;
export const selectEntityDevicesInfo = (state: RootState) => state.device.entityDevices;
export const selectDeviceInfo = (state: RootState) => state.device.device;

export default deviceSlice.reducer;
