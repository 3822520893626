import { APP_CONFIG } from "@config/constants";

export const CDN_ASSETS_URL = {
    pricingCalculator: APP_CONFIG.cdnUrl + 'pricing-calculator.jpg',
    termsConditions: APP_CONFIG.cdnUrl + 'terms-and-conditions.pdf',
    privacyPolicy: APP_CONFIG.cdnUrl + 'privacy-and-policy.pdf',
    avatarImg: APP_CONFIG.cdnUrl + 'Avatar.png',
    activateIcon: APP_CONFIG.cdnUrl + 'activate_icon.png',
    archiveIcon: APP_CONFIG.cdnUrl + 'archive_icon.png',
    contactUsIcon: APP_CONFIG.cdnUrl + 'contact_us.png',
    dotBottomImg: APP_CONFIG.cdnUrl + 'dotBottom_img.png',
    dotTopImg: APP_CONFIG.cdnUrl + 'dotTop_img.png',
    dummyInvoice: APP_CONFIG.cdnUrl + 'dummy-invoice.png',
    dummyLogo: APP_CONFIG.cdnUrl + 'dummy_logo.png',
    featuredIcon: APP_CONFIG.cdnUrl + 'featured-icon.svg',
    featuredIconPng: APP_CONFIG.cdnUrl + 'featured_icon.png',
    logoSvg: APP_CONFIG.cdnUrl + 'logo.svg',
    logoOnlySvg: APP_CONFIG.cdnUrl + 'logo-only.svg',
    masterCard: APP_CONFIG.cdnUrl + 'master_card.png',
    noNotification: APP_CONFIG.cdnUrl + 'no_notification.png',
    SecurityImg: APP_CONFIG.cdnUrl + 'security.png',
    underMaintenance: APP_CONFIG.cdnUrl + 'under_maintenance.png',
    warningIng: APP_CONFIG.cdnUrl + 'warning-ing.png',
}
