import { DEFAULT_EMPTY_VALUE } from '@config/constants';
import { RequestStatus } from '@models/async-status.enum';
import {
    IAllowanceCharge,
    IAllowanceCharges,
    IInvoice,
    IInvoiceCreation,
    IInvoiceItemCharges,
    IInvoiceLinesItem,
    IInvoicePayload,
    IPrePayment,
    IPrePayments,
    ISingleInvoice,
} from '@models/invoice.model';
import { PayloadAction, createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { INVOICE_API } from '@service/invoice.service';
import { RootState } from '@store/store';
import {
    getChargeDiscountAdditionValue,
    getInvoiceItemCalculationData,
} from '@utils/InvoiceCalculation';
import { DEFAULT_LINES_ITEMS, IS_INVOICE_ITEM_VALID } from '@utils/InvoiceUtils';
import { generateUUID } from '@utils/uuid';
import _, { uniqBy } from 'lodash';

interface IEinvoiceState {
    isLoading: boolean;
    openFilter: boolean;
    invoiceLinesItems: IInvoiceLinesItem[];
    isShowInvoiceLinesItemRequired: boolean;
    draftInvoices: {
        status: RequestStatus;
        data: ISingleInvoice[];
        totalCount: number;
    };
    invoices: {
        status: RequestStatus;
        data: ISingleInvoice[];
        totalCount: number;
    };
    invoicesSelect: {
        status: RequestStatus;
        data: ISingleInvoice[];
        hasMore: boolean;
        PageLimit: number;
        PageNumber: number;
    };
    invoice: IInvoice | null;
    draftInvoice: IInvoice | null;
    prePayments: IPrePayments;
    allowanceCharges: IAllowanceCharges;
}

const initialState: IEinvoiceState = {
    isLoading: false,
    openFilter: false,
    invoiceLinesItems: [{ ...DEFAULT_LINES_ITEMS, SerialId: generateUUID() } as IInvoiceLinesItem],
    isShowInvoiceLinesItemRequired: false,
    draftInvoices: {
        status: 'idle',
        data: [],
        totalCount: 0,
    },
    invoices: {
        status: 'idle',
        data: [],
        totalCount: 0,
    },
    invoicesSelect: {
        status: 'idle',
        data: [],
        hasMore: true,
        PageLimit: 10,
        PageNumber: 1,
    },
    invoice: null,
    draftInvoice: null,
    prePayments: [],
    allowanceCharges: [],
};

export const getDraftInvoices = createAsyncThunk(
    'getDraftInvoices',
    async ({ payload }: { payload: IInvoicePayload }) => {
        try {
            const response = await INVOICE_API.getAllDraftInvoices(payload);
            return response || { DraftInvoiceList: [], TotalCount: 0 };
        } catch (error: any) {
            throw error as any;
        }
    },
);

export const deleteDraftInvoice = createAsyncThunk('deleteDraftInvoice', async (itemId: string) => {
    try {
        await INVOICE_API.deleteDraftInvoice({ itemId });
    } catch (error: any) {
        throw error as any;
    }
});

export const getInvoices = createAsyncThunk(
    'getInvoices',
    async ({ payload }: { payload: IInvoicePayload }) => {
        try {
            const response = await INVOICE_API.getAllInvoices(payload);
            return response || { InvoiceList: [], TotalCount: 0 };
        } catch (error: any) {
            throw error as any;
        }
    },
);
export const createInvoices = createAsyncThunk(
    'createInvoices',
    async (payload: IInvoiceCreation, { getState }) => {
        try {
            const state = getState() as RootState;
            const Prepayments = state?.einvoice?.prePayments || [];
            const AllowanceCharges = state?.einvoice?.allowanceCharges || [];
            const response = await INVOICE_API.createInvoice({
                ...payload,
                Prepayments,
                AllowanceCharges,
            });
            return response || { InvoiceList: [], TotalCount: 0 };
        } catch (error: any) {
            throw error as any;
        }
    },
);
export const previewInvoices = createAsyncThunk(
    'previewInvoices',
    async (payload: IInvoiceCreation, { getState }) => {
        try {
            const state = getState() as RootState;
            const Prepayments = state?.einvoice?.prePayments || [];
            const AllowanceCharges = state?.einvoice?.allowanceCharges || [];
            const response = await INVOICE_API.previewInvoice({
                ...payload,
                Prepayments,
                AllowanceCharges,
            });
            return response;
        } catch (error: any) {
            throw error as any;
        }
    },
);
export const saveAsDraftInvoices = createAsyncThunk(
    'saveAsDraftInvoices',
    async (payload: IInvoiceCreation, { getState }) => {
        try {
            const state = getState() as RootState;
            const Prepayments = state?.einvoice?.prePayments || [];
            const AllowanceCharges = state?.einvoice?.allowanceCharges || [];
            const response = await INVOICE_API.saveAsDraft({
                ...payload,
                Prepayments,
                AllowanceCharges,
            });
            return response;
        } catch (error: any) {
            throw error as any;
        }
    },
);

export const getInvoicesForSelect = createAsyncThunk(
    'getInvoicesForSelect',
    async ({ payload }: { payload: IInvoicePayload }) => {
        try {
            const response = await INVOICE_API.getAllInvoices(payload);
            return (
                { ...response, isReset: payload.PageNumber === 1 } || {
                    InvoiceList: [],
                    TotalCount: 0,
                    isReset: payload.PageNumber === 1,
                }
            );
        } catch (error: any) {
            throw error as any;
        }
    },
);
export const getInvoice = createAsyncThunk('getInvoice', async (invoiceId: string) => {
    try {
        const response = await INVOICE_API.getInvoice(invoiceId);
        return response?.Result;
    } catch (error: any) {
        throw error as any;
    }
});

export const getDraftInvoice = createAsyncThunk('getDraftInvoice', async (itemId: string) => {
    try {
        const response = await INVOICE_API.getDraftInvoice(itemId);
        return response?.Result;
    } catch (error: any) {
        throw error as any;
    }
});

export const sendInvoiceByMail = createAsyncThunk('sendInvoiceByMail', async (payload: any) => {
    try {
        const response = await INVOICE_API.sendInvoiceByMail(payload);
        return response?.Result;
    } catch (error: any) {
        throw error as any;
    }
});

export const addInvoiceNote = createAsyncThunk('addInvoiceNote', async (payload: any) => {
    try {
        const response = await INVOICE_API.addInvoiceNote(payload);
        return response?.Result;
    } catch (error: any) {
        throw error as any;
    }
});

export const einvoicenSlice = createSlice({
    name: 'einvoicenSlice',
    initialState,
    reducers: {
        toggleFilter: (state) => {
            state.openFilter = !state.openFilter;
        },
        addNewInvoiceLineItem: (state, action: PayloadAction<IInvoiceLinesItem>) => {
            state.invoiceLinesItems = uniqBy(
                state.invoiceLinesItems.concat(action.payload),
                'SerialId',
            );
        },
        addNewMultipleInvoiceLineItem: (state, action: PayloadAction<IInvoiceLinesItem[]>) => {
            state.invoiceLinesItems = action.payload;
        },

        updateInvoiceLineItem: (
            state,
            action: PayloadAction<{ Item: Partial<IInvoiceLinesItem>; ItemId: string }>,
        ) => {
            const { Item, ItemId } = action.payload;
            const foundLineItems = state.invoiceLinesItems.map((item) =>
                item.SerialId === ItemId
                    ? getInvoiceItemCalculationData({ ...item, ...Item })
                    : item,
            );
            state.invoiceLinesItems = foundLineItems;
            state.isShowInvoiceLinesItemRequired = false;
        },
        addInvoiceLineCharge: (
            state,
            action: PayloadAction<{ LineCharge: Partial<IInvoiceItemCharges>; ItemId: string }>,
        ) => {
            const { LineCharge, ItemId } = action.payload;
            const lineItem = state.invoiceLinesItems.find((item) => item.SerialId === ItemId);

            if (lineItem) {
                !lineItem.AllowanceCharges && (lineItem.AllowanceCharges = []);
                lineItem.AllowanceCharges.push(LineCharge as IInvoiceItemCharges);
                const calculateData = getInvoiceItemCalculationData(lineItem);
                lineItem.RoundingAmount = calculateData.RoundingAmount;
                lineItem.LineExtensionAmount = calculateData.LineExtensionAmount;
                lineItem.TaxAmount = calculateData.TaxAmount;
            }
        },
        removeInvoiceLineCharge: (
            state,
            action: PayloadAction<{ LineChargeIndex: number; ItemId: string }>,
        ) => {
            const { LineChargeIndex, ItemId } = action.payload;
            const lineItem = state.invoiceLinesItems.find((item) => item.SerialId === ItemId);

            if (lineItem) {
                !lineItem.AllowanceCharges && (lineItem.AllowanceCharges = []);
                lineItem.AllowanceCharges.splice(LineChargeIndex, 1);
                const calculateData = getInvoiceItemCalculationData(lineItem);
                lineItem.RoundingAmount = calculateData.RoundingAmount;
                lineItem.LineExtensionAmount = calculateData.LineExtensionAmount;
                lineItem.TaxAmount = calculateData.TaxAmount;
            }
        },
        deleteInvoiceLineItem: (state, action: PayloadAction<string>) => {
            const newItems = state.invoiceLinesItems.filter(
                (item) => item.SerialId !== action.payload,
            );
            const defaultInvoice = {
                ...DEFAULT_LINES_ITEMS,
                SerialId: generateUUID(),
            } as IInvoiceLinesItem;

            state.invoiceLinesItems = newItems.length === 0 ? [defaultInvoice] : newItems;
        },
        resetInvoiceLineItem: (state) => {
            state.invoiceLinesItems = [
                { ...DEFAULT_LINES_ITEMS, SerialId: generateUUID() } as IInvoiceLinesItem,
            ];
        },
        resetInvoicesSelect: (state) => {
            state.invoicesSelect.status = 'idle';
            state.invoicesSelect.data = [];
            state.invoicesSelect.hasMore = true;
            state.invoicesSelect.PageNumber = 1;
            state.isShowInvoiceLinesItemRequired = false;
        },
        addPrePayment: (state, action: PayloadAction<IPrePayment>) => {
            const Id = generateUUID();
            state.prePayments.unshift({ ...action.payload, Id });
        },
        deletePrePayment: (state, action: PayloadAction<string>) => {
            state.prePayments = state.prePayments.filter(
                (payment) => payment.Id !== action.payload,
            );
        },
        resetPrePayment: (state) => {
            state.prePayments = [];
        },
        addAllowanceCharge: (state, action: PayloadAction<IAllowanceCharge>) => {
            const Id = generateUUID();
            state.allowanceCharges.unshift({ ...action.payload, Id });
        },
        deleteAllowanceCharge: (state, action: PayloadAction<string>) => {
            state.allowanceCharges = state.allowanceCharges.filter(
                (allowanceCharge) => allowanceCharge.Id !== action.payload,
            );
        },
        resetAllowanceCharge: (state) => {
            state.allowanceCharges = [];
        },
        setShowInvoiceItemRequired: (state) => {
            state.isShowInvoiceLinesItemRequired = true;
        },
        resetSingleInvoice: (state) => {
            state.invoice = null;
        },
    },
    extraReducers(builder) {
        builder
            .addCase(getInvoices.pending, (state) => {
                state.invoices.status = 'loading';
            })
            .addCase(getInvoices.rejected, (state) => {
                state.invoices.status = 'failed';
                state.invoices.data = [];
                state.invoices.totalCount = 0;
            })
            .addCase(getInvoices.fulfilled, (state, action) => {
                state.invoices.status = 'idle';
                state.invoices.data = action.payload.InvoiceList;
                state.invoices.totalCount = action.payload.TotalCount;
            })
            .addCase(getDraftInvoices.pending, (state) => {
                state.draftInvoices.status = 'loading';
            })
            .addCase(getDraftInvoices.rejected, (state) => {
                state.draftInvoices.status = 'failed';
                state.draftInvoices.data = [];
                state.draftInvoices.totalCount = 0;
            })
            .addCase(getDraftInvoices.fulfilled, (state, action) => {
                state.draftInvoices.status = 'idle';
                state.draftInvoices.data = action.payload.DraftInvoiceList;
                state.draftInvoices.totalCount = action.payload.TotalCount;
            })
            .addCase(deleteDraftInvoice.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(deleteDraftInvoice.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(deleteDraftInvoice.fulfilled, (state, action) => {
                const itemId = action.meta.arg;
                state.isLoading = false;
                state.draftInvoices.data = state.draftInvoices.data.filter(
                    (item) => item.ItemId !== itemId,
                );
                state.draftInvoices.totalCount = state.draftInvoices.totalCount - 1;
            })
            .addCase(getInvoice.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getInvoice.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(getInvoice.fulfilled, (state, action) => {
                state.isLoading = false;
                state.invoice = action.payload;
            })
            .addCase(getDraftInvoice.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getDraftInvoice.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(getDraftInvoice.fulfilled, (state, action) => {
                state.isLoading = false;
                state.draftInvoice = action.payload;
            })

            .addCase(sendInvoiceByMail.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(sendInvoiceByMail.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(sendInvoiceByMail.fulfilled, (state) => {
                state.isLoading = false;
            })
            .addCase(addInvoiceNote.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(addInvoiceNote.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(addInvoiceNote.fulfilled, (state) => {
                state.isLoading = false;
            })
            .addCase(getInvoicesForSelect.pending, (state) => {
                state.invoicesSelect.status = 'loading';
            })
            .addCase(getInvoicesForSelect.rejected, (state) => {
                state.invoicesSelect.status = 'failed';
            })
            .addCase(getInvoicesForSelect.fulfilled, (state, action) => {
                state.invoicesSelect.status = 'idle';
                const invoiceList = action?.payload?.InvoiceList || [];
                const totalCount = action?.payload?.TotalCount || 0;
                if (action.payload.isReset) {
                    state.invoicesSelect.data = invoiceList;
                    state.invoicesSelect.hasMore = invoiceList.length < totalCount;
                    state.invoicesSelect.PageNumber = 1;
                    return;
                }
                state.invoicesSelect.data = uniqBy(
                    state.invoicesSelect.data.concat(invoiceList),
                    'InvoiceId',
                );
                state.invoicesSelect.hasMore = state.invoicesSelect.data.length < totalCount;
                state.invoicesSelect.PageNumber = state.invoicesSelect.PageNumber + 1;
            })
            .addCase(createInvoices.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(createInvoices.fulfilled, (state) => {
                state.isLoading = false;
                state.prePayments = [];
                state.allowanceCharges = [];
            })
            .addCase(createInvoices.rejected, (state) => {
                state.isLoading = false;
            });
    },
});

export const {
    toggleFilter,
    addNewInvoiceLineItem,
    updateInvoiceLineItem,
    deleteInvoiceLineItem,
    resetInvoiceLineItem,
    resetInvoicesSelect,
    addPrePayment,
    deletePrePayment,
    resetPrePayment,
    addAllowanceCharge,
    deleteAllowanceCharge,
    resetAllowanceCharge,
    setShowInvoiceItemRequired,
    resetSingleInvoice,
    addNewMultipleInvoiceLineItem,
    addInvoiceLineCharge,
    removeInvoiceLineCharge,
} = einvoicenSlice.actions;

export const selectDraftInvoice = (state: RootState) => state?.einvoice?.draftInvoice || null;

export const selectDraftInvoices = (state: RootState) =>
    state?.einvoice?.draftInvoices || { data: [], status: 'idle' };

export const selectInvoices = (state: RootState) =>
    state?.einvoice?.invoices || { data: [], status: 'idle' };

export const selectInvoiceLineItems = (state: RootState) =>
    state?.einvoice?.invoiceLinesItems || [];

export const selectInvoiceValidateLineItems = (state: RootState) => {
    return state?.einvoice?.invoiceLinesItems.filter((line) => IS_INVOICE_ITEM_VALID(line)) || [];
};

export const selectIsDeleteLineItem = (state: RootState) =>
    state?.einvoice?.invoiceLinesItems?.length > 1 ||
    !!state?.einvoice?.invoiceLinesItems[0].ProductId;

export const selectInvoiceForReferences = (state: RootState) => {
    const invoicesSelect = state.einvoice?.invoicesSelect;
    return {
        ...invoicesSelect,
        data: invoicesSelect?.data?.map(
            (data) =>
                ({
                    ...data,
                    ID: data?.InvoiceId,
                    value: data?.InvoiceId,
                    label: data?.InvoiceId || DEFAULT_EMPTY_VALUE,
                } || []),
        ),
    };
};

export const selectIsFoundInvoice = (state: RootState) =>
    state.einvoice.invoices?.data?.length >= 0;

export const selectPrePayments = (state: RootState) => state.einvoice.prePayments;
export const selectTotalPrePaymentsAmount = (state: RootState) =>
    state.einvoice.prePayments?.reduce(
        (prePayment, current) => prePayment + Number(current?.Amount),
        0,
    );

export const selectAllowanceCharges = (state: RootState) => state.einvoice.allowanceCharges;

export const selectTotalAllowanceCharges = createSelector(selectAllowanceCharges, (charges) => {
    const taxCharges = charges.map((charge) => ({
        ...charge,
        Amount: (charge.AllowanceTax.Percent * charge.Amount) / 100 + charge.Amount,
    }));
    const totalTaxDiscountCharge = getChargeDiscountAdditionValue(taxCharges, 'both') || 0;
    const totalDiscountCharge = getChargeDiscountAdditionValue(charges, 'both') || 0;
    const totalCharges = getChargeDiscountAdditionValue(charges, 'charge') || 0;
    const totalDiscount = getChargeDiscountAdditionValue(charges, 'discount') || 0;
    const totalTaxAmount = totalTaxDiscountCharge - totalDiscountCharge;
    return {
        totalTaxDiscountCharge,
        totalTaxAmount,
        totalDiscountCharge,
        totalCharges,
        totalDiscount,
    };
});

export const isShowInvoiceLinesItemRequired = (state: RootState) =>
    state.einvoice.isShowInvoiceLinesItemRequired;

export const isLoadingSingleInvoice = (state: RootState) => state.einvoice.isLoading;
export const isLoadingSendInvoiceByMail = (state: RootState) => state.einvoice.isLoading;
export const isLoadingAddInvoiceNote = (state: RootState) => state.einvoice.isLoading;

export const selectInvoiceLineById = createSelector(
    [
        (state: RootState, invoiceLinesId: string) => {
            return { invoiceLines: state.einvoice.invoiceLinesItems, invoiceLinesId };
        },
    ],
    ({ invoiceLines, invoiceLinesId }) =>
        invoiceLines.find((line) => line.SerialId === invoiceLinesId),
);

export default einvoicenSlice.reducer;
