import { AxiosError } from 'axios';
import { AXIOS_INSTANCE } from '@config/axios-config';
import { APP_CONFIG } from '@config/constants';
import { IInvitation, IInvitationPayload, IInvitationResponse } from '@models/invitation.model';
import { processQuery } from '@utils/processQuery';

export const UserInvitationAPI = {
    inviteUser: async (payload: IInvitation): Promise<any> => {
        const url = APP_CONFIG.eisUrl + `InvitationCommand/Invite`;
        try {
            const response = await AXIOS_INSTANCE.post<any>(url, payload);
            return response.data;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },
    cancelInvitation: async (invitationId: string): Promise<boolean> => {
        const url = APP_CONFIG.eisUrl + `InvitationCommand/Cancel`;
        try {
            const response = await AXIOS_INSTANCE.post<any>(url, { invitationId });
            return true;
        } catch (error) {
            const err = error as AxiosError;
            return false;
        }
    },
    acceptInvitation: async (token: string): Promise<any> => {
        const url = APP_CONFIG.eisUrl + `InvitationCommand/Accept`;
        try {
            const response = await AXIOS_INSTANCE.post<any>(url, { token });
            return response.data?.Result;
        } catch (error) {
            const err = error as AxiosError;
            return err.response?.data;
        }
    },
    getMemberShip: async (): Promise<any> => {
        const url = APP_CONFIG.eisUrl + `MembershipQuery/Memberships`;
        try {
            const response = await AXIOS_INSTANCE.post<any>(url);
            return response.data;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },
    getInvitations: async (payload: IInvitationPayload): Promise<IInvitationResponse> => {
        const url = APP_CONFIG.eisUrl + `InvitationCommand/Invitations`;
        try {
            const response = await AXIOS_INSTANCE.post<any>(url, payload);
            return response.data?.Result;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },
    getInvitation: async (InvitationId: string): Promise<any> => {
        const url =
            APP_CONFIG.eisUrl +
            `InvitationQuery/Invitation${processQuery({
                invitationId: InvitationId,
            })}`;
        try {
            const response = await AXIOS_INSTANCE.get<any>(url);
            return response.data?.Result;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },
};
